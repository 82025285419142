export const isValidCPF = (cpf: string): boolean => {
    cpf = cpf.replace(/[^\d]/g, ''); // Remove caracteres não numéricos

    // Verifica se o CPF possui 11 dígitos
    if (cpf.length !== 11) return false;

    // Verifica se todos os dígitos são iguais, o que tornaria o CPF inválido
    if (/^(\d)\1+$/.test(cpf)) return false;

    // Calcula o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(9))) return false;

    // Calcula o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(10))) return false;

    return true;
};

export const isValidCNPJ = (cnpj: string): boolean => {
    cnpj = cnpj.replace(/[^\d]/g, ''); // Remove caracteres não numéricos

    // Verifica se o CNPJ possui 14 dígitos
    if (cnpj.length !== 14) return false;

    // Verifica se todos os dígitos são iguais, o que tornaria o CNPJ inválido
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // Calcula o primeiro dígito verificador
    let sum = 0;
    let weight = 2;
    for (let i = 11; i >= 0; i--) {
        sum += parseInt(cnpj.charAt(i)) * weight;
        weight = (weight === 9 ? 2 : weight + 1);
    }
    let remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (remainder !== parseInt(cnpj.charAt(12))) return false;

    // Calcula o segundo dígito verificador
    sum = 0;
    weight = 2;
    for (let i = 12; i >= 0; i--) {
        sum += parseInt(cnpj.charAt(i)) * weight;
        weight = (weight === 9 ? 2 : weight + 1);
    }
    remainder = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (remainder !== parseInt(cnpj.charAt(13))) return false;

    return true;
};