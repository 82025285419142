import { AddIcon } from "@chakra-ui/icons";
import { Grid, Flex, Button, Text } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import FranchiseTable from "./FranchiseTable";
import { useNavigate } from "react-router-dom";

export const FranchisePage = () => {
    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    return (
        <Grid minH="99vh" minW="99vw" p={3} padding={0}>
            <Flex>
                <Sidebar />
                <div className="content">
                    <Flex justify="space-between" align="center" mb={4}>
                        <Text fontSize="4xl" >Franquias</Text>
                        <Button
                            leftIcon={<AddIcon />}
                            colorScheme="green"
                            onClick={() => handleNavigate('/franchise/form')}
                        >
                            Nova Franquia
                        </Button>
                    </Flex>
                    <FranchiseTable />
                </div>
            </Flex>
        </Grid>

    );
}