import { useNavigate } from "react-router-dom";
import { createEvents } from "../../../../services/api";
import EventForm from "../EventForm";
import { Box, Flex, Grid, useToast } from "@chakra-ui/react";
import Sidebar from "../../../../components/Sidebar";
import { IEvent } from "../../../../interfaces/event-interface";
import { Header } from "../../../../components/Header";


export const EventFormPage = () => {
    const toast = useToast();
    const navigate = useNavigate();

    function newEvent(data: IEvent) {
        let isCreatedSucess = false;
        createEvents(data)
            .then(() => {
                isCreatedSucess = true;
                toast({
                    title: "Evento criado com sucesso.",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
                if (isCreatedSucess) {
                    navigate('/event');
                }
            })
            .catch(() => {
                toast({
                    title: "Erro ao criar Evento",
                    description: "Preencha os campos corretamente.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    }

    return (
        <>
            <Header />
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <EventForm onSubmit={newEvent} onClose={() => navigate('/event')} />
                        <Box style={{
                            paddingTop: '20px',
                        }}></Box>
                    </div>
                </Flex>
            </Grid>
        </>
    )
};


