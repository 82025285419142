// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.imagestyle{
    border-radius: 20%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Signup/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".img{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 50px;\n}\n.imagestyle{\n    border-radius: 20%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
