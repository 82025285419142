import React from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  Avatar,
} from '@chakra-ui/react'

import { DeleteIcon } from '@chakra-ui/icons'

import './style.css'
import Alert from '../../../../components/Alert'
import Drawer from '../../../../components/Drawer'
import EventForm from '../NotificationForm/index'

export default function EventTable() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const cancelRef = React.useRef()

  function renderActionButtons() {
    return (
      <div className='action-buttons'>
        <IconButton colorScheme='red' aria-label='Deletar evento' icon={<DeleteIcon />} onClick={onOpen} />
      </div>
    )
  }

  return (
    <>
      <TableContainer className='table-container'>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th width={'2xl'}>Título</Th>
              <Th align='center' justifyContent={'center'} alignItems={'center'}>Descrição</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody className='tr'>
            <Tr>
              <Td>Arctic Monkeys</Td>
              <Td>Novo show em são paulo</Td>
              <Td>{renderActionButtons()}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Alert
              title={'Deletar notificação'}
              confirmActionMessage={'Tem certeza que deseja deletar essa notificação ?'}
              confirmActionButtonText={'Deletar'}
              cancelActionButtonText={'Cancelar'}
              isOpen={isOpen}
              onClose={onClose}
              cancelRef={cancelRef} onConfirm={function (): void {
                  throw new Error('Function not implemented.')
              } } confirmButtonText={''}      />

      <Drawer
        title={'Editar evento'}
        isOpen={isOpenDrawer}
        onClose={onCloseDrawer}
        cancelText={'Cancelar'}
        confirmText={'Salvar'}
        children={<EventForm onSubmit={() => {}}/>}
      />
    </>
  )
}