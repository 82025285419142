import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import Sidebar from "../../components/Sidebar";
import HighlightedEventsTable from "./components/HighlightedEventsTable";

import "./styles.css";
import HighlightedEventsForm from './components/HighlightedEventsForm';
import { listHighlightedEvents, createHighlightedEvent, listHighlightedEventsByOrganizer } from '../../services/api';
import { getUser } from "../../services/auth";
import { IHighlightedEvent } from "../../interfaces/event-interface";
import { Header } from "../../components/Header";

const HighlightedEvents: React.FC = () => {
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const [highlightedEvents, setHighlightedEvents] = useState<IHighlightedEvent[]>([])
  const toast = useToast()

  function list() {
    const user = getUser();
    if (!user.rootUser) {
      listHighlightedEventsByOrganizer(user.id).then(response => {
        setHighlightedEvents(response.data.items)
      })
    } else {
      listHighlightedEvents().then(response => {
        setHighlightedEvents(response.data.items)
      })
    }
  }

  function newHighlightedEvents(data: IHighlightedEvent) {
    createHighlightedEvent(data).then(() => {
      list()
      toast({
        title: "Evento destacado com sucesso.",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    })
      .catch(() =>
        toast({
          title: 'Erro ao destacar evento',
          description: "Preencha os campos corretamente.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      )
  }

  useEffect(() => {
    list()
  }, [])

  return (
    <>
      <Header />
      <Grid minH="99vh" minW="99vw" p={3} padding={0}>
        <Flex>
          <Sidebar />
          <div className="content">
            <div className="table-header">
              <Text fontSize={"4xl"}>Eventos em destaque</Text>
              <Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" onClick={onOpenDrawer}>
                Novo
              </Button>
            </div>
            <HighlightedEventsTable highlightedEvents={highlightedEvents} listHighlightedEvents={list} />
          </div>
        </Flex>
        <Drawer
          title={'Novo destaque'}
          isOpen={isOpenDrawer}
          onClose={onCloseDrawer}
          cancelText={'Cancelar'}
          confirmText={'Salvar'}
          children={<HighlightedEventsForm onSubmit={newHighlightedEvents} onClose={onCloseDrawer} />}
        />
      </Grid>
    </>
  );
};

export default HighlightedEvents;
