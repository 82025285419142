import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import { Pagination as ReactPagination, PaginationItem, PaginationLink, Input } from 'reactstrap';

type PropsType = {
    lastPage: number
    currentPage: number
    setLastPage: React.Dispatch<React.SetStateAction<number>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    setPerPage: React.Dispatch<React.SetStateAction<number>>
    perPage: number
}

export const Pagination = (props: PropsType) => {
    const { 
        currentPage, 
        lastPage, 
        setCurrentPage, 
        perPage,
        setPerPage 
    } = props;

    const [firstPage, setFirstPage] = useState<number>(1);
    const pagesToShow = 5;

    useEffect(() => {
        const newFirstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
        setFirstPage(newFirstPage);
    }, [currentPage]);

    const handleClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleNext = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const pageNumbers = Array.from({ length: pagesToShow }, (_, index) => firstPage + index).filter(pageNumber => pageNumber <= lastPage);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px 20px 20px 20px',
            }}
        >
            <Input
                style={{
                    width: 80
                }}
                id="perPageSelect"
                name="perPage"
                type="select"
                value={perPage}
                onChange={(e) => {
                    setPerPage(Number(e.target.value))
                    setCurrentPage(1)
                }}
            >
                <option value={10}>
                    10
                </option>

                <option value={20}>
                    20
                </option>

                <option value={50}>
                    50
                </option>

                <option value={100}>
                    100
                </option>

                <option value={150}>
                    150
                </option>
            </Input>

            <ReactPagination>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink first onClick={() => handleClick(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={handlePrevious} />
            </PaginationItem>
            {pageNumbers.map(pageNumber => (
                <PaginationItem active={pageNumber === currentPage} key={pageNumber}>
                    <PaginationLink onClick={() => handleClick(pageNumber)}>
                        {pageNumber}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage === lastPage}>
                <PaginationLink next onClick={handleNext} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === lastPage}>
                <PaginationLink last onClick={() => handleClick(lastPage)} />
            </PaginationItem>
            </ReactPagination>
        </div>
        
    );
}

