import React  from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { Formik } from "formik";

import './style.css'

interface FormValues {
  title: string;
  description: string;
}

interface NotificationFormProps {
  onSubmit: (values: FormValues) => void;
}

export default function NotificationForm({ onSubmit }: NotificationFormProps) {
  
  const initialFormValues: FormValues = {
    title: "",
    description: "",
  };

  const initialRef = React.useRef(null)


  function handleSignin(values: FormValues) {
    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSignin}
      validationSchema={NotificationForm}
      validateOnChange={false}
    >
      <>
      <FormControl>
        <FormLabel>Título da notificação</FormLabel>
        <Input ref={initialRef} name="title" placeholder='Ex. Are u mine ?' />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Conteúdo</FormLabel>
        <Input name="description" placeholder='Ex. Terça feira tem show de Arctic Monkeys em Curitiba 😳' />
      </FormControl>
      </>
    </Formik>
  );
}
