import React, { useMemo, useState } from "react";
import { Button, Flex, Input } from "@chakra-ui/react";
import { FormikValues, useFormik } from 'formik';

import "./style.css";
import Dropzone, { useDropzone } from "react-dropzone";
import {  uploadFile } from '../../../../services/api';
import { ICategory } from "../../../../interfaces/category-interface";

interface FormValues {
  name: string;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface CategoryFormProps {
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
  initialValues?: FormikValues;
  categoryId?: number;
}

export default function CategoryForm({
  onSubmit,
  onClose,
  initialValues,
}: CategoryFormProps) {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
  });

  const initialFormValues: FormikValues = {
    name: "",
    image: "",
  };

  const {values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
  } = useFormik({
    initialValues: initialValues || initialFormValues,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
        onSubmit({
          name: values.name,
          image: values.image,
        } as ICategory);
        onClose();
    }
  })

  const [preview, setPreview] = useState("");
  const [uploadingImage, setUploadImage] = useState(false);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  async function handleDropFile(acceptedFiles: any ) {
    setUploadImage(true);
    const objectUrl = URL.createObjectURL(acceptedFiles[0])
    setPreview(objectUrl)

    const response = await uploadFile(acceptedFiles[0])
    setFieldValue('image', response.data.url)

    setUploadImage(false);
  }

  return (
        <>
          <Input
            placeholder="Nome"
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            className="input"
          />
          {errors.name && touched.name && errors.name}
          <Flex marginTop={10} marginBottom={10}>
            {initialValues?.image && (
              <img src={initialValues?.image} width={300} height={200} alt="preview"  />
            )}
            {preview && (
              <img src={preview} width={300} height={200} alt="preview"  />
            )}
          </Flex>
          <div className="input">
            <Dropzone onDrop={handleDropFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style } as any)}>
                    <input {...getInputProps()} />
                    <p>Arraste a imagem da categoria 😀</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className="action-buttons-custom">
            <Button
              variant={"outline"}
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              colorScheme={"blue"}
              type="submit"
              disabled={isSubmitting || uploadingImage}
              onClick={() => handleSubmit()}
            >
              Salvar
            </Button>
          </div>
        </>
  );
}
