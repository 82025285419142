import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Drawer from "../../components/Drawer";
import Sidebar from "../../components/Sidebar";
import EventTable from "./components/NotificationTable";
import NotificationForm from './components/NotificationForm';

import "./style.css";
import { Header } from "../../components/Header";

const Notification: React.FC = () => {
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  return (
    <>
      <Header />
      <Grid minH="99vh" minW="99vw" p={3} padding={0}>
        <Flex>
          <Sidebar />
          <div className="content">
            <div className="table-header">
              <Text fontSize={"4xl"}>Notificações</Text>
              <Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" onClick={onOpenDrawer}>
                Novo
              </Button>
            </div>
            <EventTable />
          </div>
        </Flex>
        <Drawer
          title={'Nova notificação'}
          isOpen={isOpenDrawer}
          onClose={onCloseDrawer}
          cancelText={'Cancelar'}
          confirmText={'Salvar'}
          children={<NotificationForm onSubmit={() => { }} />}
        />
      </Grid>
    </>
  );
};

export default Notification;
