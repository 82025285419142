import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Center, Table, TableCaption, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { EventReportInterface } from './interfaces/event-report-interface';
import { formatNumberToCurrency } from '../../util/currency';
import moment from 'moment';

type PropsType = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    event: EventReportInterface | null
}

export const DetailsModal = ({event, isOpen, setIsOpen}: PropsType) => {

    const onClose = () => setIsOpen(false);

    return (
        <>
        <Modal
            size="2xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>
                <Center><h3>INFORMAÇÕES DO EVENTO</h3></Center> 
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>

            <Table variant="striped">
                        <TableCaption>Detalhes do Evento</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Campo</Th>
                                <Th>Informação</Th>
                            </Tr>
                        </Thead>

                        { event && <>
                            <Tbody>
                                <Tr>
                                    <Td>DATA DE CRIAÇÃO</Td>
                                    <Td>{moment(event!.createdAt).format('DD/MM/YYYY HH:MM:SS')}</Td>
                                </Tr>
                                <Tr>
                                    <Td>INGRESSOS VENDIDOS</Td>
                                    <Td>{event!.totalTicketsSold}</Td>
                                </Tr>
                                <Tr>
                                    <Td>PEDIDOS</Td>
                                    <Td>{event!.totalOrders}</Td>
                                </Tr>
                                <Tr>
                                    <Td>VALOR TOTAL</Td>
                                    <Td>{formatNumberToCurrency(event!.amountTotal)}</Td>
                                </Tr>
                                <Tr>
                                    <Td>VALOR TOTAL DA EMPRESA</Td>
                                    <Td>{formatNumberToCurrency(event!.totalCompanyAmount)}</Td>
                                </Tr>
                                <Tr>
                                    <Td>VALOR TOTAL DA FRANQUIA</Td>
                                    <Td>{formatNumberToCurrency(event!.totalFranchiseAmount)}</Td>
                                </Tr>
                            </Tbody>
                        </>

                        }
                    </Table>
                
            </ModalBody>

            <ModalFooter>
                <Button onClick={onClose}>Sair</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    );
}