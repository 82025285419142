import React from "react";

import {
    Text,
} from '@chakra-ui/react'

interface ErrorMessageFormProps {
  name: string;
  errors: any;
  touched: any;
}

const ErrorMessageForm = ({
  name,
  errors,
  touched,
}: ErrorMessageFormProps) => {
  if (!errors[name] || !touched[name]) return <> </>;

  return <Text textColor={'red.500'}>{errors[name]}</Text>;
};

export default ErrorMessageForm;
