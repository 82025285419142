import { ICompanyByUser } from "../interfaces/company-interface";
import { IUser } from "../interfaces/user-interface";

export const TOKEN_KEY = "@ondeehoje-token";
export const USER_KEY = "@ondeehoje-user";
export const COMPANY_KEY = "@ondeehoje-company";

export const isAuthenticated = () => !!localStorage.getItem(TOKEN_KEY)

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const setCompany = (company: ICompanyByUser) => localStorage.setItem(COMPANY_KEY, JSON.stringify(company))

export const setUser = (user: IUser) => localStorage.setItem(USER_KEY, JSON.stringify(user))

export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY) || '{}') as IUser

export const getCompany = () => JSON.parse(localStorage.getItem(COMPANY_KEY) || '{}') as ICompanyByUser

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
};