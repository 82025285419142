import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'

interface AlertProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    onConfirm: () => void;
    confirmActionMessage: string;
    confirmButtonText: string;
    cancelActionButtonText: string;
    confirmActionButtonText: string;
    cancelRef: any;
}

export default function Alert({
    title,
    confirmActionMessage = `Tem certeza ? Você não pode desfazer essa ação depois.`,
    confirmActionButtonText = 'Confirmar',
    cancelActionButtonText = 'Cancelar',
    isOpen,
    onClose,
    cancelRef,
    onConfirm,
}: AlertProps) {
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {confirmActionMessage}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {cancelActionButtonText}
                        </Button>
                        <Button colorScheme='red' onClick={onConfirm} ml={3}>
                            {confirmActionButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}