import { Flex, Card, CardHeader, CardBody, Box, Heading, Stack, StackDivider, Icon, IconButton, Tooltip } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { FaPencilAlt } from "react-icons/fa"

interface SelectEditFranchisePageProps {
    franchiseName: string;
    franchiseId: number;
    adminId: number;
    bankAccountId: number;
}
export const SelectEditFranchisePage = ({ franchiseId, franchiseName, bankAccountId, adminId }: SelectEditFranchisePageProps) => {

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    return (
        <Card
            w='100%'
            maxW='400px'
        >
            <CardHeader
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
            >
                <Heading size='md'>Editar </Heading>
            </CardHeader>

            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Franquia
                            </Heading>
                            <Tooltip label="Editar franquia" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate('/franchise/update/' + franchiseId)}
                                    aria-label="Editar franquia"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações da franquia.
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Administrador
                            </Heading>
                            <Tooltip label="Editar Administrador" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate('/franchise/admin/update/' + adminId)}
                                    aria-label="Editar Administrador"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações do administrador.
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Conta Bancaria
                            </Heading>
                            <Tooltip label="Editar Conta Bancaria" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/franchise/banck/update/${bankAccountId}`)}
                                    aria-label="Editar Conta Bancaria"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações da conta bancaria.
                    </Box>
                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Atualizar configurações de pagamento
                            </Heading>
                            <Tooltip label="Atualizar configurações de pagamento" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/franchise/banck/config/update/${bankAccountId}`)}
                                    aria-label="Editar Conta Bancaria"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Atualizar dias de pagamento.
                    </Box>
                    {/* <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Contrato
                            </Heading>
                            <Tooltip label="Editar  Contrato" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/company/${companyId}/contract/${contractId}`)}
                                    aria-label="Editar Contrato"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações do contrato.
                    </Box> */}
                </Stack>
            </CardBody>
        </Card>
    )
}