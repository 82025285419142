
import React from "react";

import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    cancelText: string;
    confirmText: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
}

export default function Drawer({
  title,
  isOpen,
  onClose,
  children,
  size
}: DrawerProps) {
  return (
    <ChakraDrawer isOpen={isOpen} placement="right" onClose={onClose} size={size}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
}