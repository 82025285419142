import { useEffect, useState } from "react";
import { IResponseCompanyVerify } from "../../../../interfaces/company-interface";
import { verifyCompany } from "../../../../services/api"
import { Badge, CircularProgress } from "@chakra-ui/react";
import { RiAlertFill } from "react-icons/ri";

interface IPropsCompanyPending {
    companyId: number
}

export const ComponentCompaniePending = ({ companyId }: IPropsCompanyPending) => {
    const [companiesPending, setCompaniesPending] = useState<IResponseCompanyVerify>({} as IResponseCompanyVerify);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasPendecies, setHasPendecies] = useState<boolean>(false);

    const handleVerifyCompany = (companyId: number) => {
        setIsLoading(true);
        verifyCompany(companyId).then((response) => {
            const { data, error, message, statusCode } = response.data;
            if (statusCode === 400) {
                setHasPendecies(true);
                setCompaniesPending(response.data);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleVerifyCompany(companyId);
    }, [])

    return (
        <>
            {
                isLoading ? <CircularProgress isIndeterminate color="green.300" /> :
                    hasPendecies &&
                    <RiAlertFill
                        color="orange"
                        size={20}
                        style={{ marginRight: 5 }}
                    />
            }
        </>

    )
}