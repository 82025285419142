import { Flex, Spinner, Heading, Grid, GridItem, Divider, Text, Center, Badge, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ICompanyDetails } from "../../../interfaces/company-interface";
import { getComapanyDetails, listBanks } from "../../../services/api";
import { FaRegUserCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { FaFileContract } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { IoIosBusiness } from "react-icons/io";
import { IBank } from "../../../interfaces/banckaccount-interface";
import { HeaderCompany } from "../Components/Header";
import { SelectEditCompanyPage } from "../Forms/Edit/SelectEditPage/Index";




export const CompanyDetails = () => {
    const [company, setCompany] = useState<ICompanyDetails>({} as ICompanyDetails);
    const [loading, setLoading] = useState<boolean>(true);
    const [bankName, setBankName] = useState<string>('' as string);
    const [banks, setBanks] = useState<IBank[]>([])
    const [isLoadingBanckName, setIsLoadingBankName] = useState<boolean>(true);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleGetDayOfWeek = (day: number) => {
        switch (day) {
            case 0:
                return 'Domingo'
            case 1:
                return 'Segunda-feira'
            case 2:
                return 'Terça-feira'
            case 3:
                return 'Quarta-feira'
            case 4:
                return 'Quinta-feira'
            case 5:
                return 'Sexta-feira'
            case 6:
                return 'Sábado'
            default:
                return 'Dia não encontrado'
        }
    }


    const handleGetBanks = (companyData: ICompanyDetails) => {
        setIsLoadingBankName(true)
        listBanks().then((response) => {
            if (response.data.data) {
                setBanks(response.data.data);
                const bankName = response.data.data.find((bank) => bank.code === companyData.bankAccounts[0].bank_code)
                if (bankName) {
                    setBankName(bankName.long_name)
                }
            }
            setIsLoadingBankName(false)
        }).catch((error) => {
            console.log(error)
            setIsLoadingBankName(false)
        }).finally(() => {
            setIsLoadingBankName(false)
        })
    }

    let { id } = useParams();

    function init() {
        setLoading(true);
        getComapanyDetails(id!).then((response) => {
            setCompany(response.data.data);
            setLoading(false);
            handleGetBanks(response.data.data);
        })
            .catch((error) => {
                setLoading(false);
            });
    }

    useEffect(() => {
        init();
    }, [])

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (!company) {
        return <Text>Could not load company details.</Text>;
    }

    return (
        <>
            <HeaderCompany
                companyName={company.company_name}
                companyId={Number(company.id)}
                onOpenModalSelect={onOpen}
            />
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        {
                            !loading ?
                                <Grid
                                    templateColumns='repeat(1, 1fr)'
                                    gap={6}
                                >
                                    <Grid
                                        templateColumns='repeat(2, 1fr)'
                                        gap={6}
                                    >
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h1" size="xl" mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <IoIosBusiness
                                                            size={30}
                                                        />
                                                        Empresa
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <strong>Nome:</strong>   {company.company_name}
                                                </Text>   <Text>
                                                    <strong>Email:</strong> {company.email}
                                                </Text>
                                                <Text>
                                                    <strong>{company.type === 'individual' ? 'CPF' : 'CNPJ'}</strong> {company.document_number}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo:</strong> {company.type === 'individual' ? 'Pessoa Fisica' : 'Pessoa Juridica'}
                                                </Text>
                                                {
                                                    company.type === 'individual' && <Text>
                                                        <strong>Profissão:</strong>  {company.professional_occupation}
                                                    </Text>
                                                }
                                                {
                                                    company.type === 'individual' && <Text>
                                                        <strong>Data de nascimento:</strong>  {company.birthdate}
                                                    </Text>
                                                }
                                                <Text>
                                                    <strong>Telefone:</strong> ({company.ddd}) {company.number_phone}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo de telefone:</strong> {company.type_phone}
                                                </Text>
                                                <Text>
                                                    <strong>Faturamento Anual:</strong> R${company.annual_revenue}
                                                </Text>
                                                <Text>
                                                    <strong>Nome Fantasia:</strong> {company.trading_name}
                                                </Text>
                                            </GridItem>
                                            <GridItem>
                                                <Text>
                                                    <strong>Endereço:</strong> {`
                                                    ${company.address?.city} - ${company.address?.state},
                                                    ${company.address?.street}, ${company.address?.number},
                                                    ${company.address?.complementary},
                                                    `}
                                                </Text>
                                            </GridItem>
                                        </GridItem>
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <FaRegUserCircle />
                                                        Organizador
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <strong>Nome:</strong> {company.organizers[0].name}
                                                </Text>
                                                <Text>
                                                    <strong>Email:</strong> {company.organizers[0].email}
                                                </Text>
                                                <Text>
                                                    <strong>CPF:</strong> {company.organizers[0].document_number}
                                                </Text>
                                                <Text>
                                                    <Text>
                                                        <strong>Data de Nascimento:</strong>   {company.organizers[0].birthdate}
                                                    </Text>
                                                    <strong>Telfone:</strong> ({company.ddd}) {company.organizers[0].phone_number}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo de telefone:</strong> {company.organizers[0].phone_type}
                                                </Text>
                                                <Text>
                                                    <strong>Profissão:</strong> {company.organizers[0].professional_occupation}
                                                </Text>
                                                <Text>
                                                    <strong>Renda mensal:</strong> {company.organizers[0].monthly_income}
                                                </Text>
                                            </GridItem>
                                        </GridItem>
                                    </Grid>
                                    <Grid
                                        templateColumns='repeat(2, 1fr)'
                                        gap={6}
                                    >
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4}>
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <AiFillBank />
                                                        Conta Bancaria
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <Flex>
                                                        <strong
                                                            style={{ marginRight: '5px' }}
                                                        >{'Banco: '}</strong>  {
                                                            isLoadingBanckName ? <Spinner
                                                                colorScheme="blue"
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                            />
                                                                :
                                                                ` ${bankName}`
                                                        }
                                                    </Flex>
                                                </Text>
                                                <Text>
                                                    <strong>Nome:</strong> {company.bankAccounts[0].holder_name}
                                                </Text>
                                                <Text>
                                                    <strong>CPF:</strong> {company.bankAccounts[0].holder_document}
                                                </Text>
                                                <Text>
                                                    <strong>Tipo:</strong> {company.bankAccounts[0].account_type === 'checking' ? 'Conta Corrente' : 'Conta Poupança'}
                                                </Text>
                                                <Text>
                                                    <strong>Número da conta:</strong> {company.bankAccounts[0].account_number}
                                                </Text>
                                                <Text>
                                                    <strong>Dígito da conta:</strong> {company.bankAccounts[0].account_check_digit}
                                                </Text>
                                                <Text>
                                                    <strong>Número da agência:</strong> {company.bankAccounts[0].branch_number}
                                                </Text>
                                                <Text>
                                                    <strong>Dígito da agência:</strong> {company.bankAccounts[0].branch_check_digit}
                                                </Text>
                                                <Text>
                                                    <Flex>
                                                        <strong style={{ marginRight: '5px' }}>{"Transferencia:"}</strong> {
                                                            company.bankAccounts[0].transfer_enabled ? <Badge
                                                                colorScheme="green"
                                                                height={8}
                                                                width={16}
                                                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                                borderRadius={6}
                                                            >
                                                                Ativo
                                                            </Badge>
                                                                :
                                                                <Badge
                                                                    colorScheme="red"
                                                                    height={8}
                                                                    width={16}
                                                                    style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                                    borderRadius={6}
                                                                >
                                                                    Inativo
                                                                </Badge>
                                                        }
                                                    </Flex>
                                                </Text>
                                                <Text>
                                                    <strong>{'Intervalo de transferencia: '}</strong>{company.bankAccounts[0].transfer_interval === 'daily' ? 'Diario' : company.bankAccounts[0].transfer_interval === 'weekly' ? 'Semanal' : company.bankAccounts[0].transfer_interval === 'monthly' ? 'Mensal' : 'Anual'}
                                                </Text>
                                                {
                                                    company.bankAccounts[0].transfer_interval === 'weekly' && <Text>
                                                        <strong>{"Dia da semana: "}</strong>
                                                        {
                                                            handleGetDayOfWeek(company.bankAccounts[0].transfer_day)
                                                        }
                                                    </Text>
                                                }
                                                {
                                                    company.bankAccounts[0].transfer_interval === 'monthly' && <Text>
                                                        <strong>Dia do mês:</strong> {company.bankAccounts[0].transfer_day}
                                                    </Text>
                                                }
                                            </GridItem>
                                        </GridItem>
                                        <GridItem
                                            style={{ border: '1px solid #e2e8f0', padding: '20px', borderRadius: '6px' }}
                                        >
                                            <Flex
                                                justifyContent={'center'}
                                            >
                                                <Heading as="h2" size="lg" mt={6} mb={4} >
                                                    <Flex
                                                        justifyItems={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <FaFileContract
                                                            size={26}
                                                        />
                                                        Contrato
                                                    </Flex>
                                                </Heading>
                                            </Flex>
                                            <GridItem>
                                                <Text>
                                                    <Flex justifyItems={'center'} alignItems={'center'}>
                                                        <strong style={{ marginRight: '5px' }}>
                                                            {"Contrato:"}
                                                        </strong> {company.contract?.is_enable ? <Badge
                                                            colorScheme="green"
                                                            height={8}
                                                            width={16}
                                                            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                            borderRadius={6}
                                                        >
                                                            Ativo
                                                        </Badge> :
                                                            <Badge
                                                                colorScheme="red"
                                                                height={8}
                                                                width={16}
                                                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                                borderRadius={6}
                                                            >
                                                                Inativo
                                                            </Badge>}
                                                    </Flex>
                                                </Text>
                                                <Text>
                                                    <strong>Valor:</strong> {company.contract?.company_fee_percent}%
                                                </Text>
                                                <Text>
                                                    <strong>Inicio:</strong> {new Date(company.contract?.created_at).getDate()}/{new Date(company.contract?.created_at).getMonth() + 1}/{new Date(company.contract?.created_at).getFullYear()}
                                                </Text>
                                                <Text>
                                                    <strong>
                                                        {' Valor final para o cliente: '}
                                                    </strong>
                                                    {company.contract.final_customer_fee_percent}
                                                </Text>
                                            </GridItem>
                                        </GridItem>
                                    </Grid>
                                </Grid>
                                :
                                <Center>
                                    <Spinner size="xl"
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                    />
                                </Center>
                        }
                    </div>
                </Flex>
            </Grid >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><ModalCloseButton /></ModalHeader>
                    <ModalBody>
                        <SelectEditCompanyPage
                            companyName={company.company_name}
                            companyId={Number(company.id)}
                            organizerId={Number(company.organizers[0].id)}
                            bankAccountId={Number(company.bankAccounts[0].id)}
                            contractId={Number(company.contract?.id)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>

    );
};
