import { useEffect, useRef, useState } from "react";

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

// import "./style.css";
import Alert from "../../../components/Alert";
import { listCompanies, listFranchise, verifyStatusFranchise, } from "../../../services/api";
import { IFranchise, IResponseVerifyFranchise } from "../../../interfaces/franchise-interface";
import { ComponentStatusFranchise } from "../Components/FranchiseStatus";
import { useNavigate } from "react-router-dom";

export default function FranchiseTable({
}: {
    }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const [franchiseList, setFranchiseList] = useState<IFranchise[]>([]);
    const toast = useToast();
    const navigate = useNavigate();

    const handleNavigate = (path: string, state?: any) => {
        navigate(path, { state });
    }

    useEffect(() => {
        listFranchise().then((response) => {
            if (response.data.statusCode !== 200) {
                console.log(response.data.message);
                return toast({
                    title: "Erro ao buscar franquias",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            setFranchiseList(response.data.data);
        })
    }, [])

    const handleVerifyFranchise = async (franchiseId: number) => {
        const response = await verifyStatusFranchise(franchiseId);
        const { statusCode, data } = response.data;
        const isHasPendenci = handleVerifyHasPendecies(response.data);
        return isHasPendenci ? response.data : false;
    }

    const handleVerifyHasPendecies = (data: IResponseVerifyFranchise) => {
        const adminFranchise = data.data.admins;
        if (adminFranchise.length <= 0) {
            return true;
        }
        const banckAccountFranchise = data.data.bankAccounts;
        if (banckAccountFranchise.length <= 0) {
            return true;
        }
        return false;
    }

    function handleConfirmDelete() {
    }

    return (
        <>
            <TableContainer className="table-container">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th width={"0"}></Th>
                            <Th width={"20"}>Nome</Th>
                            <Th>Email</Th>
                            <Th>Telefone</Th>
                        </Tr>
                    </Thead>
                    <Tbody className="tr">
                        {
                            franchiseList.length === 0 ? (
                                <Tr>
                                    <Td colSpan={4}>Nenhuma franquia encontrada...</Td>
                                </Tr>
                            ) : (
                                franchiseList.map((franchise) => (
                                    <Tr
                                        onClick={async () => {
                                            const response = await handleVerifyFranchise(Number(franchise.id));
                                            if (response) {
                                                handleNavigate('/franchise/form', { data: response.data })
                                            } else {
                                                handleNavigate(`/franchise/${franchise.id}`)
                                            }
                                        }}
                                    >
                                        <Td>
                                            <ComponentStatusFranchise franchiseId={franchise.id} />
                                        </Td>
                                        <Td>{franchise.company_name}</Td>
                                        <Td>{franchise.email}</Td>
                                        <Td>
                                            <div>
                                                {franchise.ddd} {franchise.number_phone}
                                            </div>
                                        </Td>
                                    </Tr>
                                ))
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <Alert
                title={"Deletar franquia"}
                confirmActionMessage={"Tem certeza que deseja deletar este franquia ?"}
                confirmActionButtonText={"Deletar"}
                cancelActionButtonText={"Cancelar"}
                isOpen={isOpen}
                onClose={onClose}
                cancelRef={cancelRef}
                onConfirm={handleConfirmDelete}
                confirmButtonText={""}
            />
        </>
    );
}
