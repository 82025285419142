import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Grid,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
} from "@chakra-ui/react";
import React, { MouseEvent, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import EventTable from "./components/EventTable";

import { listEvents, createEvents, listEventsByOrganizer, changePasswordFirstLogin } from "../../services/api";
import EventForm from "./components/EventForm";

import "./style.css";
import { getUser } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { IEvent } from "../../interfaces/event-interface";
import { IUser } from "../../interfaces/user-interface";
import { Header } from "../../components/Header";
import { Pagination } from "../../components/Pagination/pagination";
import { SearchInput } from "../../components/SearchInput/search-input";



const Event: React.FC = () => {

  const [user, setUser] = useState<IUser>({} as IUser);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [lastPage, setLastPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(50)

  const handleSearch = async () => {

    const params = `?search=${searchInput}&limit=${perPage}&page=${currentPage}`

    if (user.user_type == 'ORGANIZER') { //========== SE FOR ORGANIZADOR =========
      if(!user.id) return;
      listEventsByOrganizer(user.id, params)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)
          }
        )

    } else if(user.user_type == 'ADMIN'){
      listEvents(params)
      .then(
        response => {
          setEvents(response.data.items)
          setLastPage(response.data.meta.totalPages)
          setCurrentPage(response.data.meta.currentPage)
        }
      )
      .catch(error => console.log(error))
      }
  }

  async function handleChangePasswordUser(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (password !== passwordConfirm || password === '' || passwordConfirm === '') {
      toast({
        title: "Senhas não conferem.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    if (password.length < 6) {
      toast({
        title: "Senha deve conter no minimo 6 caracteres.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    if (password === "123456") {
      toast({
        title: "Senha não pode ser igual a anterior.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    const res = await changePasswordFirstLogin({ email: user.email, password: password });
    if (res) {
      onCloseDrawerChangePassword();
      toast({
        title: "Senha alterada com sucesso.",
        status: "success",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
    } else {
      toast({
        title: "Erro ao alterar senha.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
    }
  }

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenDrawerChangePassword,
    onOpen: onOpenDrawerChangePassword,
    onClose: onCloseDrawerChangePassword,
  } = useDisclosure();

  const toast = useToast();
  const navigate = useNavigate();

  const [events, setEvents] = useState<IEvent[]>([]);

  function list() {
    const user = getUser();
    setUser(user)
    user.first_login && user.user_type === "ORGANIZER" && onOpenDrawerChangePassword();
    console.log(user)
    if (user.user_type == 'ORGANIZER') { //========== SE FOR ORGANIZADOR =========
      listEventsByOrganizer(user.id, `?search=&limit=${perPage}&page=1`)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)
          }
        )
    } else if(user.user_type == 'ADMIN') { //========== SE FOR ADMIN =========
      listEvents(`?search=&limit=${perPage}&page=1`)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)

          }
        )
    }
  }

  function newEvent(data: IEvent) {
    createEvents(data)
      .then(() => {
        list();
        toast({
          title: "Evento criado com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao criar Evento",
          description: "Preencha os campos corretamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  const handleNavigateNewEventPage = () => {
    navigate('/event/form');
  }

  useEffect(() => {
    handleSearch()
  }, [currentPage, perPage])

  useEffect(() => {
    if (searchInput === '') {
      list();
    }
  }, [searchInput]);

  return (
    <>
      <Header />
      <Grid minH="99vh" minW="99vw" p={3} padding={0}>
        <Flex>
          <Sidebar />
          <div className="content">
            <div className="table-header">
              <Text fontSize={"4xl"}>Eventos</Text>

              <SearchInput
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleSearch={handleSearch}
                placeholder="Busque por um evento"
              />

              <Button
                leftIcon={<AddIcon />}
                colorScheme="blue"
                variant="solid"
                onClick={() => handleNavigateNewEventPage()}
              >
                Novo
              </Button>
            </div>

            <EventTable
              events={events}
              list={list}
             />

            <Pagination
              lastPage={lastPage}
              currentPage={currentPage}
              setLastPage={setLastPage}
              setCurrentPage={setCurrentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />

          </div>
        </Flex>

        <Modal isOpen={isOpenDrawer} onClose={onCloseDrawer} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Novo evento</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EventForm onSubmit={newEvent} onClose={onCloseDrawer} />
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* <MapPage /> */}
        {/* <Map latitude={0} longitude={0} onChange={() => { }} /> */}

        <Modal
          isOpen={isOpenDrawerChangePassword}
          onClose={onCloseDrawerChangePassword}
          size="6xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              style={{
                fontWeight: 'bold',
                fontSize: '1rem',
                color: 'blackAlpha.900',
                fontFamily: 'Roboto, sans-serif',
                width: '100%',
              }}
            >
              Alterar senha
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: 'blackAlpha.900',
                    fontFamily: 'Roboto, sans-serif',
                    textAlign: 'center',
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  Seja bem-vindo {user.name} 🚀
                </span>
                <form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  onSubmit={(e) => handleChangePasswordUser(e)}
                >
                  <Text
                    style={{
                      fontSize: '1rem',
                      color: 'blackAlpha.900',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Altere sua senha para continuar
                  </Text>
                  <Text
                    style={{
                      color: 'blackAlpha.900',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Digite sua nova senha
                  </Text>
                  <Input
                    style={{
                      width: '100%',
                      padding: '8px',
                      marginTop: '8px',
                      marginBottom: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                      outline: 'none',
                    }}
                    type="password"
                    name="password"
                    placeholder="Digite sua nova senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Input
                    style={{
                      width: '100%',
                      padding: '8px',
                      marginTop: '8px',
                      marginBottom: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                      outline: 'none',
                    }}
                    type="password"
                    name="password"
                    placeholder="Confirme sua nova senha"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                  <Button
                    style={{
                      width: '100%',
                      padding: '8px',
                      marginTop: '8px',
                      marginBottom: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                      outline: 'none',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                    type="submit"
                    colorScheme="green"
                  >
                    Alterar senha
                  </Button>
                </form>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>

      </Grid>
    </>

  );
};

export default Event;
