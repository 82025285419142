import React, { useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  useToast,
  Image,
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

import "./style.css";
import Alert from "../../../../components/Alert";
import { deleteHilightedEvent } from '../../../../services/api';
import { formatDateToEventCard } from '../../../../util/date';
import { IHighlightedEvent } from "../../../../interfaces/event-interface";
import moment from "moment";


export default function HighlightedEventsTable({
  highlightedEvents,
  listHighlightedEvents
}: {
  highlightedEvents: IHighlightedEvent[];
  listHighlightedEvents: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [highlightedEvent, setCategory] = useState<IHighlightedEvent>();

  function middlewareDelete(highlightedEvent: IHighlightedEvent) {
    setCategory(highlightedEvent);
    onOpen();
  }

  function handleConfirmDelete() {
    if (!highlightedEvent?.id) return 

    deleteHilightedEvent(highlightedEvent.id)
      .then(() => {
        listHighlightedEvents();
        onClose();
        toast({
          title: "Evento destacado deletado com sucesso",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao deletar evento destacado.",
          description: "Verifique se o evento ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function renderActionButtons(highlightedEvent: IHighlightedEvent) {
    return (
      <div className="action-buttons">
        <IconButton
          colorScheme="red"
          aria-label="Deletar evento destacado"
          icon={<DeleteIcon />}
          onClick={() => middlewareDelete(highlightedEvent)}
        />
      </div>
    );
  }

  return (
    <>
      <TableContainer className="table-container">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th width={"20"}>Título</Th>
              <Th>Expira em</Th>
              <Th>Ícone</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody className="tr">
            {highlightedEvents.map((highlightedEvent) => (
              <Tr>
                <Td>{highlightedEvent.event?.title}</Td>
                <Td>{moment(highlightedEvent.event?.finalDate?.toString()).format('DD/MM/YYYY HH:mm:ss')}</Td>
                <Td>
                  <Image src={highlightedEvent.event?.image} width={50} height={10}/>
                </Td>
                <Td>{renderActionButtons(highlightedEvent)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Alert
        title={"Deletar evento destacado"}
        confirmActionMessage={"Tem certeza que deseja deletar este destaque ?"}
        confirmActionButtonText={"Deletar"}
        cancelActionButtonText={"Cancelar"}
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        onConfirm={handleConfirmDelete}
        confirmButtonText={""}
      />
    </>
  );
}
