// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address {
    box-sizing: border-box;
    width: 240px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    margin-top: 12px;
  }

  .addressField {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Map/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,0CAA0C;IAC1C,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;IACf,aAAa;IACb,uBAAuB;EACzB","sourcesContent":[".address {\n    box-sizing: border-box;\n    width: 240px;\n    padding: 12px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    background-color: rgba(255, 255, 255, 0.8);\n    border-radius: 4px;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);\n    font-size: 14px;\n    position: absolute;\n    left: 50%;\n    margin-left: -120px;\n    margin-top: 12px;\n  }\n\n  .addressField {\n    box-sizing: border-box;\n    border: 1px solid transparent;\n    width: 100%;\n    height: 32px;\n    margin-bottom: 8px;\n    padding: 0 12px;\n    border-radius: 4px;\n    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);\n    font-size: 14px;\n    outline: none;\n    text-overflow: ellipses;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
