import React from 'react';

import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { AiFillTag } from 'react-icons/ai';
import { MdHome, MdWork } from "react-icons/md";
import { FaRegBuilding, FaUser } from 'react-icons/fa';
import { GiShoppingCart, GiTicket } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom';
import { AttachmentIcon, ChatIcon, ExternalLinkIcon, StarIcon } from '@chakra-ui/icons';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import { getUser } from '../../services/auth';

const Sidebar: React.FC = () => {
    const navigate = useNavigate()

    const user = getUser()

    const role = user.user_type;
    const displayName = user?.name?.split(' ')[0] || user.email

    function handleNavigate(path: string) {
        navigate(path)
    }

    function handleLogout() {
        localStorage.clear()
        window.location.reload()
    }

    const menuItemns = [
        {
            item: <MenuItem icon={<AiFillTag />} onClick={() => handleNavigate('/category')}>Categorias</MenuItem>,
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<GiTicket />} onClick={() => handleNavigate('/event')}>Eventos Ativos</MenuItem>, 
            role: ['ORGANIZER', 'ADMIN']
        },
        {
            item: <MenuItem icon={<GiShoppingCart />} onClick={() => handleNavigate('/orders')}>Pedidos</MenuItem>, 
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<StarIcon />} onClick={() => handleNavigate('/highlights')}>Destaques</MenuItem>,
            role: ['ADMIN', 'ORGANIZER']
        },
        {
            item: <MenuItem icon={<ChatIcon />} onClick={() => handleNavigate('/notification')}>Notificações</MenuItem>,
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<AttachmentIcon />} onClick={() => handleNavigate('/report')}>Relatórios</MenuItem>,
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<MdHome />} onClick={() => handleNavigate('/organizer')}>Empresas</MenuItem>,
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<FaRegBuilding />} onClick={() => handleNavigate('/franchise')}>Franquias</MenuItem>,
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<FaUser />} onClick={() => handleNavigate('/users')}>Usuários</MenuItem>, 
            role: ['ADMIN']
        },
        {
            item: <MenuItem icon={<ExternalLinkIcon />} onClick={handleLogout}>Sair</MenuItem>,
            role: ['ADMIN', 'ORGANIZER']
        },
    ]

    function renderMenuItems() {
        return menuItemns.map((item, index) => {
            if (item.role.includes(role)) {
                return item.item
            }
        })
    }

    return (
        <ProSidebar>
            <Flex justifyContent={'center'} alignItems={'center'}>
                <Avatar
                    src='https://avataaars.io/?avatarStyle=Circle&topType=LongHairNotTooLong&accessoriesType=Round&hairColor=Platinum&facialHairType=BeardMajestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Hearts&eyebrowType=Angry&mouthType=Vomit&skinColor=Brown'
                />
                <Flex flexDirection={'column'} margin={8}>
                    <Text fontSize={'1xl'} >{displayName}</Text>
                    <Text fontSize={'small'} >{role}</Text>
                </Flex>
            </Flex>
            <Menu iconShape="circle">
                {renderMenuItems()}
            </Menu>
        </ProSidebar>
    );
}

export default Sidebar;