import { useEffect, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";

import { MdHome } from "react-icons/md";
import "./style.css";
import { listCompanies, verifyCompany } from "../../../services/api";
import { ICompany, IResponseCompanyVerify } from "../../../interfaces/company-interface";
import { useNavigate } from "react-router-dom";
import { ComponentCompaniePending } from "../../Organizer/CompanyTable/components/CompaniPedending";

export default function CompanyTable({
}: {
    }) {
    const [companiesPending, setCompaniesPending] = useState<IResponseCompanyVerify>({} as IResponseCompanyVerify);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompany[]>([]);
    let hasPendeciesboolean = false;
    const navigate = useNavigate()

    const handleNavigate = (path: string, state?: any) => {
        navigate(path, { state });
    }

    const handleVerifyCompany = async (companyId: number): Promise<IResponseCompanyVerify> => {
        setIsLoading(true);
        const response = await verifyCompany(companyId);
        if (response.data.statusCode === 400) {
            hasPendeciesboolean = true
            setCompaniesPending(response.data);
        }
        setIsLoading(false);
        return response.data;
    }

    useEffect(() => {
        listCompanies().then((response) => {
            setCompany(response.data.data);
        })
    }, [])

    return (
        <>
            <TableContainer className="table-container">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th width={"0"}></Th>
                            <Th >Nome</Th>
                            <Th>Email</Th>
                            <Th>Telefone</Th>
                        </Tr>
                    </Thead>
                    <Tbody className="tr">
                        {
                            company.length === 0 ? (
                                <Tr>
                                    <Td colSpan={4}>Nenhuma empresa encontrada...</Td>
                                </Tr>
                            ) : (
                                company.map((company) => (
                                    <Tr
                                        onClick={async () => {
                                            const response = await handleVerifyCompany(Number(company.id));
                                            if (hasPendeciesboolean) {
                                                handleNavigate('/organizer/form', { data: response.data })
                                            } else {
                                                handleNavigate(`/company/${company.id}`)
                                            }
                                        }}
                                    >
                                        <Td>
                                            <ComponentCompaniePending companyId={Number(company.id)} />
                                        </Td>
                                        <Td>{company.company_name}</Td>
                                        <Td>{company.email}</Td>
                                        <Td>
                                            <div>
                                                {company.ddd} {company.number_phone}
                                            </div>
                                        </Td>

                                    </Tr>
                                ))
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
}
