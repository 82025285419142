import { Flex, Card, CardHeader, CardBody, Box, Heading, Stack, StackDivider, Icon, IconButton, Tooltip } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { FaPencilAlt } from "react-icons/fa"

interface SelectEditCompanyPageProps {
    companyName: string;
    companyId: number;
    organizerId: number;
    bankAccountId: number;
    contractId: number;
}
export const SelectEditCompanyPage = ({ companyId, companyName, bankAccountId, contractId, organizerId }: SelectEditCompanyPageProps) => {

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    return (
        <Card
            w='100%'
            maxW='400px'
        >
            <CardHeader
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
            >
                <Heading size='md'>Editar </Heading>
            </CardHeader>

            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Empresa
                            </Heading>
                            <Tooltip label="Editar Empresa" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate('/company/update/' + companyId)}
                                    aria-label="Editar Empresa"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações da empresa.
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Organizador
                            </Heading>
                            <Tooltip label="Editar Organizador" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate('/organizer/update/' + organizerId)}
                                    aria-label="Editar Organizador"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações do organizador.
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Atualizar Conta Bancaria
                            </Heading>
                            <Tooltip label="Atualizar Conta Bancaria" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/banck/update/${bankAccountId}`)}
                                    aria-label="Editar Conta Bancaria"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Trocar Conta Bancaria
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Atualizar configurações de pagamento
                            </Heading>
                            <Tooltip label="Atualizar configurações de pagamento" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/banck/config/update/${bankAccountId}`)}
                                    aria-label="Editar Conta Bancaria"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Atualizar dias de pagamento.
                    </Box>

                    <Box>
                        <Flex
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Heading size='xs' textTransform='uppercase'>
                                Contrato
                            </Heading>
                            <Tooltip label="Editar  Contrato" aria-label="A tooltip">
                                <IconButton
                                    colorScheme="telegram"
                                    size='sm'
                                    onClick={() => handleNavigate(`/company/${companyId}/contract/${contractId}`)}
                                    aria-label="Editar Contrato"
                                    icon={<Icon as={FaPencilAlt} />}
                                />
                            </Tooltip>
                        </Flex>
                        Editar informações do contrato.
                    </Box>
                </Stack>
            </CardBody>
        </Card>
    )
}