import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  Image,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { Row } from "reactstrap";

interface DataTableI {
    columns: any[]
    rows: any[]
    isLoading: boolean
}

export default function Datatable(props: DataTableI): JSX.Element {
    const {
        columns,
        rows,
        isLoading
    } = props

  return (
    <>
      <TableContainer className="table-container">
        <Table variant="simple">

        { isLoading
          ? <div
              style={{
                width: '100%'
              }}
            >
              <Center>
                <Spinner
                  size='xl'
                  color="#2B6CB0"
                  thickness="5px"
                />
              </Center>
            </div>
          : 
          <>
            <Thead>
              <Tr>
                  {columns.map(obj => <Th>{obj.label}</Th>)}
              </Tr>
            </Thead>

            <Tbody className="tr">
              {rows.map(obj => 
                  <Tr key={obj.id}>
                      {Object.keys(obj).map(key => <Td>{obj[key]}</Td>)}
                  </Tr>)
              }
            </Tbody>
          </>
          }
          
        </Table>
      </TableContainer>
    </>
  );
}
