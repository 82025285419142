// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
    margin-bottom: 16px;
}

.action-buttons-custom {
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    width: 100%;

    margin-top: 40%;
}`, "",{"version":3,"sources":["webpack://./src/screens/HighlightedEvents/components/HighlightedEventsForm/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;;IAEb,mBAAmB;;IAEnB,8BAA8B;;IAE9B,WAAW;;IAEX,eAAe;AACnB","sourcesContent":[".input {\n    margin-bottom: 16px;\n}\n\n.action-buttons-custom {\n    display: flex;\n\n    flex-direction: row;\n\n    justify-content: space-between;\n\n    width: 100%;\n\n    margin-top: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
