import React, { useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  useToast,
  Image,
} from "@chakra-ui/react";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

import "./style.css";
import Alert from "../../../../components/Alert";
import Drawer from "../../../../components/Drawer";
import CategoryForm from "../CategoryForm";
import {
  deleteCategories,
  updateCategories,
} from "../../../../services/api";
import { ICategory } from "../../../../interfaces/category-interface";

export default function CategoryTable({
  categories,
  listCategories
}: {
  categories: ICategory[];
  listCategories: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [category, setCategory] = useState<ICategory>();

  function middlewareEdit(category: ICategory) {
    setCategory(category);
    onOpenDrawer();
  }

  function middlewareDelete(category: ICategory) {
    setCategory(category);
    onOpen();
  }

  function handleConfirmDelete() {
    if (!category?.id) return 

    deleteCategories(category.id)
      .finally(() => {
        listCategories();
        onClose();
        toast({
          title: "Categoria deletada com sucesso",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao deletar categoria.",
          description: "Verifique se a categoria ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function handleUpdate(data: ICategory) {
    if (!category?.id) return

    updateCategories(category.id, data)
      .finally(() => {
        listCategories()
        onCloseDrawer()
        toast({
          title: "Categoria atualizada.",
          description: "A categoria foi atualizada com sucesso.",
          status: "success",
          duration: 4000,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar categoria.",
          description: "Verifique se a categoria ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function renderActionButtons(category: ICategory) {
    return (
      <div className="action-buttons">
        <IconButton
          colorScheme="blue"
          aria-label="Editar categoria"
          icon={<EditIcon />}
          onClick={() => middlewareEdit(category)}
        />
        <IconButton
          colorScheme="red"
          aria-label="Deletar categoria"
          icon={<DeleteIcon />}
          onClick={() => middlewareDelete(category)}
        />
      </div>
    );
  }

  return (
    <>
      <TableContainer className="table-container">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th width={"2xl"}>Nome</Th>
              <Th>Imagem</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody className="tr">
            {categories.map((category) => (
              <Tr>
                <Td>{category.name}</Td>
                <Td>
                  <Image src={category.image} width={50} height={10}/>
                </Td>
                <Td>{renderActionButtons(category)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Alert
        title={"Deletar categoria"}
        confirmActionMessage={"Tem certeza que deseja deletar esta categoria?"}
        confirmActionButtonText={"Deletar"}
        cancelActionButtonText={"Cancelar"}
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        onConfirm={handleConfirmDelete}
        confirmButtonText={""}
      />

      <Drawer
        title={"Editar categoria"}
        isOpen={isOpenDrawer}
        onClose={onCloseDrawer}
        cancelText={"Cancelar"}
        confirmText={"Salvar"}
        children={<CategoryForm onSubmit={handleUpdate} initialValues={category} categoryId={category?.id} onClose={onCloseDrawer} />}
      />
    </>
  );
}
