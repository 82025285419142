import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Category from "./screens/Category";
import Event from "./screens/Event";
import Notification from "./screens/Notification";
import Signin from "./screens/Signin";
import HighlightedEvents from './screens/HighlightedEvents/index';
import { isAuthenticated } from "./services/auth";
import Signup from './screens/Signup/index';
import { EventFormPage } from "./screens/Event/components/EventFormPage";
import CompanyPage from "./screens/Company";
import { FormsPage } from "./screens/Company/Forms";
import { FranchisePage } from "./screens/Franchise";
import { FranchiseFormsPage } from "./screens/Franchise/Forms";
import { CompanyDetails } from "./screens/Company/CompanyDetails";
import { FranchiseDetails } from "./screens/Franchise/FranchiseDetails";
import { CompanyFormEdit } from "./screens/Company/Forms/Edit/CompanyForm";
import { Orders } from "./screens/Orders/orders";
import { Users } from "./screens/users/users";
import { OrganizerFormEdit } from "./screens/Company/Forms/Edit/OrganizerForm";
import { ContractFormEdit } from "./screens/Company/Forms/Edit/ContractForm";
import { ContaBancariaFormEdit } from "./screens/Company/Forms/Edit/BanckAccountForm";
import { FranchiseFormEdit } from "./screens/Franchise/Forms/Edit/FranchiseForm";
import { AdminFormEdit } from "./screens/Franchise/Forms/Edit/AdminForm";
import { ContaBancariaFranquiaFormEdit } from "./screens/Franchise/Forms/Edit/BanckAccountForm";
import { ConfigContaBancariaFormEdit } from "./screens/Company/Forms/Edit/BacnkAccountConfigForm";
import { ConfigContaBancariaFranquiaFormEdit } from "./screens/Franchise/Forms/Edit/ConfigBanckAccountForm";
import { EventsReport } from "./screens/Reports/events-report";

export default function MyRoutes() {

  if (!isAuthenticated()) {
    return (
      <Router>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<Signin />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/category" element={<Category />} />
        <Route path="/event" element={<Event />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/highlights" element={<HighlightedEvents />} />
        <Route path="/event/form/" element={<EventFormPage />} />
        <Route path="/organizer" element={<CompanyPage />} />
        <Route path="/organizer/form" element={<FormsPage />} />
        <Route path="/organizer/update/:id" element={<OrganizerFormEdit />} />
        <Route path="/company/:id" element={<CompanyDetails />} />
        <Route path="/company/update/:id" element={<CompanyFormEdit />} />
        <Route path="/banck/update/:id" element={<ContaBancariaFormEdit />} />
        <Route path="/company/:companyId/contract/:id" element={<ContractFormEdit />} />
        <Route path="/franchise" element={<FranchisePage />} />
        <Route path="/franchise/form" element={<FranchiseFormsPage />} />
        <Route path="/franchise/:id" element={<FranchiseDetails />} />
        <Route path="/users" element={<Users />} />
        <Route path="/franchise/update/:id" element={<FranchiseFormEdit />} />
        <Route path="/franchise/admin/update/:id" element={<AdminFormEdit />} />
        <Route path="/franchise/banck/update/:id" element={<ContaBancariaFranquiaFormEdit />} />
        <Route path="/banck/config/update/:id" element={<ConfigContaBancariaFormEdit />} />
        <Route path="/franchise/banck/config/update/:id" element={<ConfigContaBancariaFranquiaFormEdit />} />
        <Route path="/report" element={<EventsReport />} />


        <Route path="/" element={<Event />} />
        <Route path="*" element={<Event />} />
      </Routes>
    </Router>
  );
}