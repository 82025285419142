import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import Sidebar from "../../components/Sidebar";
import CategoryTable from "./components/CategoryTable";

import "./style.css";
import CategoryForm from './components/CategoryForm/index';
import { createCategories, listCategories } from "../../services/api";
import { ICategory } from "../../interfaces/category-interface";
import { Header } from "../../components/Header";

const Category: React.FC = () => {
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const [categories, setCategories] = useState<ICategory[]>([])
  const toast = useToast()

  function list() {
    listCategories().then((response) => {
      setCategories(response.data.items)
    })
  }

  function newCategory(data: ICategory) {
    createCategories(data).then(() => {
      list()
      toast({
        title: "Categoria criada com sucesso.",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    })
      .catch(() =>
        toast({
          title: 'Erro ao criar categoria',
          description: "Preencha os campos corretamente.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      )
  }

  useEffect(() => {
    list()
  }, [])

  return (
    <>
      <Header />
      <Grid minH="99vh" minW="99vw" p={3} padding={0}>
        <Flex>
          <Sidebar />
          <div className="content">
            <div className="table-header">
              <Text fontSize={"4xl"}>Categorias</Text>
              <Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" onClick={onOpenDrawer}>
                Novo
              </Button>
            </div>
            <CategoryTable categories={categories} listCategories={list} />
          </div>
        </Flex>
        <Drawer
          title={'Nova categoria'}
          isOpen={isOpenDrawer}
          onClose={onCloseDrawer}
          cancelText={'Cancelar'}
          confirmText={'Salvar'}
          children={<CategoryForm onSubmit={newCategory} onClose={onCloseDrawer} />}
        />
      </Grid>
    </>
  );
};

export default Category;
