import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Popover,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { FormikValues, useFormik } from "formik";

import "./style.css";
import Dropzone, { useDropzone } from "react-dropzone";
import { AddIcon } from "@chakra-ui/icons";
import {
  createTickets,
  getAllCompanies,
  listCategories,
  listTicketsByEvent,
  uploadFile,
} from "../../../../services/api";
import TicketForm from "../TicketForm";
import TicketTable from "../TicketTable";
import validation from "./validation";
import ErrorMessageForm from "../../../../components/ErrorMessageForm";
import Map from "../../../../components/Map";
import { getCompany, getUser } from "../../../../services/auth";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { ICategory } from "../../../../interfaces/category-interface";
import { IEvent } from "../../../../interfaces/event-interface";
import { ITicket } from "../../../../interfaces/ticket-interface";
import { Header } from "../../../../components/Header";
import { CompanyInterface, IResponseCreateCompany } from "../../../../interfaces/company-interface";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

interface EventFormProps {
  onSubmit: (values: IEvent) => void;
  initialValues?: IEvent;
  eventId?: number;
  onClose?: () => void;
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function EventForm({
  onSubmit,
  initialValues,
  eventId,
  onClose: onCloseDrawer,
}: EventFormProps) {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [preview, setPreview] = useState<string | undefined>();
  const [uploadingImage, setUploadImage] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selected, setSelected] = useState({ lat: 0, lng: 0 });
  const [isCompanyIdError, setIsCompanyIdError] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string>('');
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const initialEventFormValue: FormikValues = {
    title: "",
    description: "",
    zip: "",
    street: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    latitude: undefined,
    longitude: undefined,
    categoryId: undefined,
    company_id: undefined
  };


  const { setFieldValue, values, handleSubmit, handleChange, errors, touched } =
    useFormik({
      initialValues: initialValues || initialEventFormValue,
      onSubmit: (values, { setSubmitting }) => {
        const user = getUser();

        if(companyId.length === 0){
          setIsCompanyIdError(true)
          return
        }else{
          setIsCompanyIdError(false)
        }

        onSubmit({
          ...(values as IEvent),
          categoryId: Number(values.categoryId),
          userId: user.id,
          company_id: Number(companyId)
        });
        setSubmitting(false);
        // if (onCloseDrawer) {
        //   onCloseDrawer();
        // }
      },
      validationSchema: validation,
    });

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [tickets, setTickets] = useState<ITicket[]>([]);

  const fetchAllCompanies = async () => {
    try {
      const {data} =  await getAllCompanies()
      setCompanies(data.data)
      
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as empresas!')
    }
  }

  function fetchCategories() {
    listCategories().then((response) => {
      setCategories(response.data.items);
    });
  }

  function fetchTickets(eventId: number) {
    listTicketsByEvent(eventId).then((response) => {
      setTickets(response.data);
    });
  }

  async function handleDropFile(acceptedFiles: any) {
    setUploadImage(true);
    const objectUrl = URL.createObjectURL(acceptedFiles[0]);
    setPreview(objectUrl);

    const response = await uploadFile(acceptedFiles[0]);
    setFieldValue("image", response.data.url);

    setUploadImage(false);
  }

  useEffect(() => {
    fetchCategories();

    fetchAllCompanies()

    const user = getUser();

    if(user.company_id){
      setCompanyId(`${user.company_id}`)
    }else{
      setIsAdmin(true)
    }


    if (eventId) {
      fetchTickets(eventId);

      setFieldValue("categoryId", initialValues?.categoryId);
      setFieldValue("city", initialValues?.address.city);
    }
  }, [eventId, initialValues, setFieldValue]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else if (String(values.zip)?.length === 8) {
      fetch(`https://viacep.com.br/ws/${values.zip}/json/`)
        .then((response) => response.json())
        .then((data) => {
          data.logradouro && setFieldValue("street", data.logradouro);
          data.bairro && setFieldValue("neighborhood", data.bairro);
          data.localidade && setFieldValue("city", data.localidade);
          data.uf && setFieldValue("state", data.uf);
        });

      if (String(values.zip).replace(/[^0-9]/g, '') === '65800000') {
        setFieldValue("latitude", -7.53292);
        setFieldValue("longitude", -46.035);

        return;
      }
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${String(
          values.zip
        ).replace(/[^\d]/g, "")}&key=AIzaSyBx6rdNnnQgS8MDL5qG2QRd0-_4zQwIJKA`
      ).then((response) => {
        response.json().then((data) => {
          if (data.results.length) {
            setFieldValue("latitude", data.results[0].geometry.location.lat);
            setFieldValue("longitude", data.results[0].geometry.location.lng);
          }
        });
      });
    }
  }, [values.zip, setFieldValue]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function renderTicketsSection() {
    return (
      <>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={10}
        >
          <Text fontSize={"xl"} style={{ marginTop: 20 }}>
            Ingressos
          </Text>
          <Button leftIcon={<AddIcon />} onClick={onOpen}>
            Novo ingresso
          </Button>
        </Flex>

        <TicketTable
          eventId={eventId as number}
          tickets={tickets}
          list={fetchTickets}
        />
      </>
    );
  }

  function newTicket(data: ITicket) {
    if (!eventId) return;

    createTickets({ ...data, eventId }).then(() => {
      onClose();
      fetchTickets(eventId);
    });
  }

  function onMapChange(latitude: number, longitude: number) {
    setFieldValue('latitude', latitude);
    setFieldValue('longitude', longitude);
  }

  const toLocalISOString = (date: Date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, -1);
  };

  useEffect(() => {
    if(initialValues?.company_id){
      setCompanyId(`${initialValues.company_id}`)
    }
  },[])

  return (
    <>
      <TicketForm isOpen={isOpen} onClose={onClose} onSubmit={newTicket} />
      <form onSubmit={handleSubmit}>
        <Text fontSize={"xl"}>Cadastre um novo evento</Text>
        <Divider className="space" />
        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <FormControl>
            <FormLabel>CEP</FormLabel>
            <Input
              className="input"
              type="text"
              name="zip"
              placeholder="CEP"
              onChange={handleChange}
              value={values.zip}
              errorBorderColor="pink.400"
              isInvalid={!!errors.zip && !!touched.zip}
            />
            <ErrorMessageForm errors={errors} touched={touched} name="zip" />
          </FormControl>

          <GridItem colSpan={2} rowSpan={2}>
            <FormControl>
              <FormLabel>Rua</FormLabel>
              <Input
                className="input"
                type="text"
                name="street"
                placeholder="Rua"
                onChange={handleChange}
                value={values.street}
                errorBorderColor="pink.400"
                isInvalid={!!errors.street && !!touched.street}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="street"
              />
            </FormControl>
          </GridItem>
        </Grid>

        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <FormControl          >
            <FormLabel>Cidade</FormLabel>
            <Input
              className="input"
              type="text"
              name="city"
              placeholder="Cidade"
              onChange={(e) => {
                handleChange(e);
              }}
              value={values.city}
              errorBorderColor="pink.400"
              isInvalid={!!errors.city && !!touched.city}
            />
            <ErrorMessageForm errors={errors} touched={touched} name="city" />
          </FormControl>

          <FormControl>
            <FormLabel>Bairro</FormLabel>
            <Input
              className="input"
              type="text"
              name="neighborhood"
              placeholder="Bairro"
              onChange={handleChange}
              value={values.neighborhood}
              errorBorderColor="pink.400"
              isInvalid={!!errors.neighborhood && !!touched.neighborhood}
            />
            <ErrorMessageForm
              errors={errors}
              touched={touched}
              name="neighborhood"
            />
          </FormControl>

          <GridItem colSpan={0} rowSpan={2}>
            <FormControl>
              <FormLabel>Estado</FormLabel>
              <Input
                className="input"
                type="text"
                name="state"
                placeholder="Estado"
                onChange={handleChange}
                value={values.state}
                errorBorderColor="pink.400"
                isInvalid={!!errors.state && !!touched.state}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="state"
              />
            </FormControl>
          </GridItem>
        </Grid>
        {/* <MapPage /> */}

        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
          <FormControl>
            <FormLabel>Número</FormLabel>
            <Input
              className="input"
              type="text"
              name="number"
              placeholder="Número"
              onChange={handleChange}
              value={values.number}
              errorBorderColor="pink.400"
              isInvalid={!!errors.number && !!touched.number}
            />
            <ErrorMessageForm errors={errors} touched={touched} name="number" />
          </FormControl>

          <FormControl hidden>
            <FormLabel>Latitude</FormLabel>
            <Input
              className="input"
              type="text"
              name="latitude"
              placeholder="Latitude"
              onChange={handleChange}
              value={values.latitude}
            />
            <ErrorMessageForm
              errors={errors}
              touched={touched}
              name="latitude"
            />
          </FormControl>

          <FormControl hidden>
            <FormLabel>Longitude</FormLabel>
            <Input
              className="input"
              type="text"
              name="longitude"
              placeholder="Longitude"
              onChange={handleChange}
              value={values.longitude}
            />
          </FormControl>
        </Grid>
        <div style={{ width: '100%', height: 400 }}>
          <Text fontSize={"xl"}>Localização do evento</Text>
          <Map latitude={values.latitude} longitude={values.longitude} onChange={onMapChange} />
        </div>

        <Divider className="space" />

        <Grid templateColumns="repeat(1, 1fr)" gap={2}>
          <GridItem colSpan={2} rowSpan={2}>
            <FormControl>
              <FormLabel>Título do evento</FormLabel>
              <Input
                className="input"
                type="text"
                name="title"
                placeholder="Título do evento"
                onChange={handleChange}
                value={values.title}
                errorBorderColor="pink.400"
                isInvalid={!!errors.title && !!touched.title}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="title"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2} rowSpan={2}>
            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <Textarea
                className="input"
                placeholder="Programação do evento e mais detalhes !"
                name="description"
                onChange={handleChange}
                value={values.description}
                errorBorderColor="pink.400"
                isInvalid={!!errors.description && !!touched.description}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="description"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2} rowSpan={2}>
            <FormControl>
              <FormLabel>Imagem promocional do evento</FormLabel>
              <Dropzone onDrop={handleDropFile}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ style } as any)}>
                      <input {...getInputProps()} />
                      <p>
                        Arraste alguma imagem do evento (A dimensão recomendada
                        é de 1600 x 838) 😀
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <Flex marginTop={20} marginBottom={20}>
                {initialValues?.image && (
                  <img src={initialValues.image} alt="Imagem do evento" />
                )}

                {preview && <img src={preview} alt="Imagem do evento" />}
              </Flex>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={2}
          style={{ marginTop: 10 }}
        >

          { isAdmin &&
            <GridItem rowSpan={2}>
              <FormControl>
                <FormLabel>Empresa</FormLabel>
                <Select
                  className="input"
                  name="company_id"
                  placeholder="Selecione a empresa"
                  onChange={(e) => setCompanyId(e.target.value)}
                  value={companyId}
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.company_id && !!touched.company_id}
                >
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.company_name ?? company.name}
                    </option>
                  ))}
                </Select>
                { isCompanyIdError &&
                  <div style={{color: 'red'}}>A empresa é obrigatória</div>
                }
                
              </FormControl>
            </GridItem>
          }
          
          <GridItem rowSpan={2}>
            <FormControl>
              <FormLabel>Categoria</FormLabel>
              <Select
                className="input"
                name="categoryId"
                placeholder="Categoria do evento"
                onChange={handleChange}
                value={values.categoryId}
                errorBorderColor="pink.400"
                isInvalid={!!errors.categoryId && !!touched.categoryId}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="categoryId"
              />
            </FormControl>
          </GridItem>

          <GridItem rowSpan={2}>
            <FormControl>
              <FormLabel>Data de início</FormLabel>
              <Input
                className="input"
                name="initialDate"
                placeholder="Início do evento"
                size="md"
                type="datetime-local"
                onChange={handleChange}
                // value={
                //   initialValues?.initialDate
                //     ? new Date(values.initialDate as Date)
                //       ?.toISOString()
                //       ?.split(".")[0]
                //     : values.initialDate
                // }
                value={
                  values.initialDate
                    ? toLocalISOString(new Date(values.initialDate))
                    : ''
                }
                errorBorderColor="pink.400"
                isInvalid={!!errors.initialDate && !!touched.initialDate}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="initialDate"
              />
            </FormControl>
          </GridItem>
          <GridItem rowSpan={2}>
            <FormControl>
              <FormLabel>Data de término</FormLabel>
              <Input
                className="input"
                name="finalDate"
                placeholder="Final do evento"
                size="md"
                type="datetime-local"
                onChange={handleChange}
                // value={
                //   initialValues?.finalDate
                //     ? new Date(values.finalDate as Date)
                //       ?.toISOString()
                //       ?.split(".")[0]
                //     : values.finalDate
                // }
                value={
                  values.finalDate
                    ? toLocalISOString(new Date(values.finalDate))
                    : ''
                }
                errorBorderColor="pink.400"
                isInvalid={!!errors.finalDate && !!touched.finalDate}
              />
              <ErrorMessageForm
                errors={errors}
                touched={touched}
                name="finalDate"
              />
            </FormControl>
          </GridItem>
        </Grid>
        {eventId && renderTicketsSection()}
        <div className="action-buttons-custom-event">
          <Button variant={"solid"} colorScheme={"red"} onClick={onCloseDrawer} style={{ width: "100%", marginRight: '16px' }}>
            Cancelar
          </Button>
          <Button
            colorScheme={"blue"}
            type="submit"
            isDisabled={uploadingImage} style={{ width: "100%", marginLeft: '16 px' }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
  );
}


const PlacesAutocomplete = ({ setSelected }: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };
}