import { AddIcon } from "@chakra-ui/icons";
import { Grid, Flex, Button, Text } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import CompanyTable from "./CompanyTable";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";


const CompanyPage: React.FC = () => {
    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }


    return (
        <>
            <Header />
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <Flex justify="space-between" align="center" mb={4}>
                            <Text fontSize="4xl" >Empresas</Text>
                            <Button
                                leftIcon={<AddIcon />}
                                colorScheme="green"
                                onClick={() => handleNavigate('/organizer/form')}
                            >
                                Nova Empresa
                            </Button>
                        </Flex>
                        <CompanyTable />
                    </div>
                </Flex>
            </Grid>
        </>
    );
}

export default CompanyPage;