// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10vh;
    margin-right: 20px;
    width: 100%;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .itemForm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Company/Forms/Edit/ContractForm/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,aAAa;IACb,WAAW;EACb","sourcesContent":[".content {\n    display: flex;\n    flex-direction: column;\n    margin-left: 20px;\n    margin-top: 10vh;\n    margin-right: 20px;\n    width: 100%;\n  }\n  \n  .table-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .itemForm {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    padding: 10px;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
