// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useState } from "react";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, } from '@react-google-maps/api';
import "./style.css";

interface MapProps {
  latitude: number;
  longitude: number;
  onChange: (latitude: number, longitude: number) => void;
}

export default function Map({ latitude, longitude, onChange }: MapProps) {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [map, setMap] = useState<google.maps.Map>();
  latitude = latitude || -7.522596;
  longitude = longitude || -46.055221;

  const onLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  };
  const onPlacesChanged = () => {
    const places = searchBox!.getPlaces();
    console.log(places);
    const place = places![0];
    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    map?.panTo(location);
  }

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
  }


  return <LoadScript
    googleMapsApiKey={"AIzaSyCk2k1rweSp-DiNUvDgpKJZH0RVmyyrta0"}
    libraries={['places']}
  >
    <GoogleMap
      id="fb6e84868b647e12"
      onLoad={onMapLoad}
      mapContainerStyle={{ width: '100%', height: '100%', }}
      center={{
        lat: latitude,
        lng: longitude
      }}
      zoom={13}
      onClick={(e) => {
        onChange(e.latLng.lat(), e.latLng.lng());
      }}
    >
      <div className="address">
        <StandaloneSearchBox
          onLoad={onLoad}
          onPlacesChanged={onPlacesChanged}
        >
          <input className="addressField" placeholder="Digite um endereço " />
        </StandaloneSearchBox>
        <Marker position={{
          lat: latitude,
          lng: longitude
        }}
        />
      </div>
    </GoogleMap >
  </LoadScript>
}
