import axios, { AxiosResponse } from 'axios'
import { IContaBancariaCreate, IResponseCreateContaBancaria, IResponseBank, IResponseContaBancaria, IContaBancariaUpdate, IResponseContaBancariaUpdate } from '../interfaces/banckaccount-interface';
import { ICategory } from '../interfaces/category-interface';
import { ICreateCompany, IResponseCreateCompany, IResponseCompany, IResponseCompanyByUser, IResponseCompanyById, IResponseCompanyVerify, IResponseCompanyDetails, IUpdateCompany, IResponseUpdateCompany, IContaBancariaConfigUpdate } from '../interfaces/company-interface';
import { IContractDto, IContractUpdateDto, IResponseContract, IResponseUpdateContract } from '../interfaces/contract-interface';
import { IEvent, IHighlightedEvent } from '../interfaces/event-interface';
import { IOrganizerDto, IOrganizerUpdateDto, IResponseOrganizer } from '../interfaces/organizer-interface';
import { ITicket } from '../interfaces/ticket-interface';
import { IUser } from '../interfaces/user-interface';
import { IConfigBankAccountFranchise, ICreateFranchise, IResponseContaBancariaFranchiseUpdate, IResponseCreateFranchise, IResponseFranchiseById, IResponseFranchiseDetails, IResponseListFranchise, IResponseUpdateFranchise, IResponseVerifyFranchise, IUpdateFranchise } from '../interfaces/franchise-interface';
import { ICreateAdmin, IResponseAdminById, IResponseCreateAdmin, IResponseUpdateAdmin, IUpdateAdmin } from '../interfaces/admin-interface';
import { ICreateBankAccountFranchise, IResponseCreateBankAccountFranchise } from '../interfaces/bank-account';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

export interface IPaginatedResponse<T> {
    items: T[];
    meta: {
        totalItems: number;
        itemCount: number;
        itemsPerPage: number;
        totalPages: number;
        currentPage: number;
    }
}

export const signin = (email: string, password: string) => api.post('/auth/signin', {
    email,
    password
}) as Promise<AxiosResponse<{ accessToken: string, user: IUser }>>

export const signup = (email: string, password: string, name: string, phone: string, cpf: string, birthDate: string) => api.post('/auth/signup', {
    email,
    password,
    name,
    cpf,
    phone,
    birthDate
}) as Promise<AxiosResponse<{ email: string, name: string, password: string }>>

export const listCategories = () => api.get('/category') as Promise<AxiosResponse<IPaginatedResponse<ICategory>>>

export const createCategories = (data: ICategory) => api.post('/category', data) as Promise<AxiosResponse<ICategory>>

export const updateCategories = (id: number, data: Partial<ICategory>) => api.put(`/category/${id}`, data)

export const deleteCategories = (id: number) => api.delete(`/category/${id}`)

export const listEvents = (params?: string) => api.get(`/event/${params ?? ''}`) as Promise<AxiosResponse<IPaginatedResponse<IEvent>>>

export const listEventsByOrganizer = (id: number, params?: string) => api.get(`/event/organizer/${id}${params ?? ''}`) as Promise<AxiosResponse<IPaginatedResponse<IEvent>>>

export const listHighlightedEvents = () => api.get('/highlighted') as Promise<AxiosResponse<IPaginatedResponse<IHighlightedEvent>>>

export const listHighlightedEventsByOrganizer = (id: number) => api.get(`/highlighted/organizer/${id}`) as Promise<AxiosResponse<IPaginatedResponse<IHighlightedEvent>>>

export const createHighlightedEvent = (data: IHighlightedEvent) => api.post('/highlighted', data) as Promise<AxiosResponse<IHighlightedEvent>>

export const deleteHilightedEvent = (id: number) => api.delete(`/highlighted/${id}`)

export const createEvents = (data: IEvent) => api.post('/event', data) as Promise<AxiosResponse<IEvent>>

export const updateEvents = (id: number, data: Partial<IEvent>) => api.put(`/event/${id}`, data)

export const deleteEvents = (id: number) => api.delete(`/event/${id}`)

export const listTicketsByEvent = (id: number) => api.get('/ticket', {
    params: {
        eventId: id
    }
}) as Promise<AxiosResponse<ITicket[]>>

export const createTickets = (data: ITicket) => api.post('/ticket', data) as Promise<AxiosResponse<ITicket>>

export const updateTickets = (id: number, data: Partial<ITicket>) => api.put(`/ticket/${id}`, data)

export const deleteTickets = (id: number) => api.delete(`/ticket/${id}`)

export const uploadFile = (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return api.post('/s3/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const createCompany = (data: ICreateCompany) => api.post('/company', data) as Promise<AxiosResponse<IResponseCreateCompany>>
export const createOrganizer = (data: IOrganizerDto) => api.post('/organizer', data) as Promise<AxiosResponse<IResponseOrganizer>>
export const createBankAccount = (data: IContaBancariaCreate) => api.post('/bank_account', data) as Promise<AxiosResponse<IResponseCreateContaBancaria>>
export const createContract = (data: IContractDto) => api.post('/contract', data) as Promise<AxiosResponse<IResponseContract>>
export const listOrganizers = () => api.get('/organizer') as Promise<AxiosResponse<IPaginatedResponse<IResponseOrganizer>>>
export const getOrganizerById = (id: number) => api.get(`/organizer/${id}`) as Promise<AxiosResponse<IResponseOrganizer>>
export const listCompanies = () => api.get('/company') as Promise<AxiosResponse<IResponseCompany>>
export const getCompanyByOrganize = (userId: number) => api.get(`/organizer/by_user/${userId}`) as Promise<AxiosResponse<IResponseCompanyByUser>>
export const getCompanyById = (companyId: number) => api.get(`/company/${companyId}`) as Promise<AxiosResponse<IResponseCompanyById>>
export const changePasswordFirstLogin = (data: { email: string, password: string }) => api.post('/auth/change-password-first-login', data) as Promise<AxiosResponse<{ status: string, message: string }>>
export const listFranchise = () => api.get('/franchise') as Promise<AxiosResponse<IResponseListFranchise>>
export const createFranchise = (data: ICreateFranchise) => api.post('/franchise', data) as Promise<AxiosResponse<IResponseCreateFranchise>>
export const createAdmin = (data: ICreateAdmin) => api.post('/admin', data) as Promise<AxiosResponse<IResponseCreateAdmin>>
export const updateAdmin = ({ data, id }: { data: IUpdateAdmin, id: number }) => api.put(`/admin/${id}`, data) as Promise<AxiosResponse<IResponseUpdateAdmin>>
export const listBanks = () => api.get('/bank') as Promise<AxiosResponse<IResponseBank>>
export const verifyCompany = (companyId: number) => api.get(`/company/verify/${companyId}`) as Promise<AxiosResponse<IResponseCompanyVerify>>
export const createBankAccountFranchise = (data: ICreateBankAccountFranchise) => api.post('/bank_account/franchise', data) as Promise<AxiosResponse<IResponseCreateBankAccountFranchise>>
export const verifyStatusFranchise = (franchiseId: number) => api.get(`/franchise/verify/${franchiseId}`) as Promise<AxiosResponse<IResponseVerifyFranchise>>
export const getComapanyDetails = (companyId: string) => api.get(`/company/detail/${companyId}`) as Promise<AxiosResponse<IResponseCompanyDetails>>
export const getFranchiseDetails = (franchiseId: string) => api.get(`/franchise/detail/${franchiseId}`) as Promise<AxiosResponse<IResponseFranchiseDetails>>
export const updateCompany = (companyId: number, data: IUpdateCompany) => api.put(`/company/${companyId}`, data) as Promise<AxiosResponse<IResponseUpdateCompany>>

export const updateOrganizer = ({ organizerId, data }: { organizerId: number, data: IOrganizerUpdateDto }) => api.put(`/organizer/${organizerId}`, data) as Promise<AxiosResponse<IResponseOrganizer>>
export const updateContract = ({ data, id }: { data: IContractUpdateDto, id: number }) => api.put(`/contract/${id}`, data) as Promise<AxiosResponse<IResponseUpdateContract>>
export const getContractById = (id: number) => api.get(`/contract/${id}`) as Promise<AxiosResponse<IResponseContract>>
export const getBanckAccountById = (id: number) => api.get(`/bank_account/${id}`) as Promise<AxiosResponse<IResponseContaBancaria>>
export const getFranchiseById = (id: number) => api.get(`/franchise/${id}`) as Promise<AxiosResponse<IResponseFranchiseById>>
export const getBackAccountById = ({ id }: { id: number }) => api.get(`/bank_account/${id}`) as Promise<AxiosResponse<IResponseContaBancaria>>
export const getAdminFranchise = (admin: number) => api.get(`/admin/${admin}`) as Promise<AxiosResponse<IResponseAdminById>>
export const updateFranchise = ({ data, id }: { data: IUpdateFranchise, id: number }) => api.put(`/franchise/${id}`, data) as Promise<AxiosResponse<IResponseUpdateFranchise>>
export const updateConfigBanckAccount = ({ data, id }: { data: IContaBancariaConfigUpdate, id: number }) => api.put(`/bank_account/${id}`, data) as Promise<AxiosResponse<IResponseContaBancariaUpdate>>
export const updateConfigBankAccountFranchise = ({ data, id }: { data: IConfigBankAccountFranchise, id: number }) => api.put(`/bank_account/franchise/${id}`, data) as Promise<AxiosResponse<IResponseContaBancariaFranchiseUpdate>>
//====================== PEDIDOS ==============================================
export const getAllOrdersByFilters = (page: number, limit: number, startDate: string, endDate: string, search: string, typeSearch: string, status: string | undefined) => api.get(`/order/get_by_filters?page=${page}&limit=${limit}&date_ini=${startDate}&date_end=${endDate}${status === 'todos' ? '' : '&status=' + status}${search === '' ? '' : typeSearch === 'id' ? '&order_id=' + search : '&search=' + search}`)
export const refoundOrder = (orderId: number) => api.delete(`/order/${orderId}`)

//====================== USUÁRIOS ==============================================
export const getUsersByFilter = (page: number, limit: number, search: string, userType: string) => api.get(`/auth/users/filter?page=${page}&limit=${limit}${search.length == 0 ? '' : '&search=' + search}${userType.length === 0 ? '' : '&userType=' + userType}`)
export const updateUser = (userId: number, data: any) => api.put(`/auth/user/${userId}`, data)
export const deleteUser = (userId: number) => api.delete(`/auth/user/${userId}`)

//======================= EMPRESAS ================================================
export const getAllCompanies = () => api.get(`/company`)

//======================= EMPRESAS ================================================
export const getEventsReport = ({startDate, endDate, page, limit, companyId}: {startDate: string, endDate: string, page: number, limit: number, companyId: string}) => api.get(`/event/report/filter?date_ini=${startDate}&date_end=${endDate}&page=${page}&limit=${limit}${companyId.length > 0 ? '&company_id=' + companyId : ''}`)