import { useState } from "react"
import { Button, FormControl, FormLabel, Input, Spinner, useToast } from "@chakra-ui/react";
import './style.css';
import { useNavigate } from "react-router-dom";
import { IContractDto } from "../../../../../interfaces/contract-interface";
import { createContract } from "../../../../../services/api";


interface PropsContract {
    finalizer: () => void // A função é passada como uma propriedade chamada 'onClick'
    backTab: (index: number) => void // A função é passada como uma propriedade chamada 'onClick'
    company_id: number,
    setContractprops: (contract: IContractDto) => void
}


export const ContractForm = ({ company_id, setContractprops, backTab }: PropsContract) => {
    const [cotract, setContract] = useState<IContractDto>({} as IContractDto);
    const [finalCustomerFeePercent, setFinalCustomerFeePercent] = useState<string>("");
    const [companyFeePercent, setCompanyFeePercent] = useState<string>("");
    const [isEnable, setIsEnable] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contractIsCreated, setContractIsCreated] = useState<boolean>(false);
    const navigate = useNavigate();
    const toast = useToast();

    const handleSaveContract = () => {
        setContract({
            final_customer_fee_percent: finalCustomerFeePercent.toString(),
            company_fee_percent: companyFeePercent.toString(),
            is_enable: isEnable,
            company_id: company_id
        })
    }

    const handleCreateContract = () => {
        setIsLoading(true);
        const data = {
            final_customer_fee_percent: finalCustomerFeePercent.toString(),
            company_fee_percent: companyFeePercent.toString(),
            is_enable: isEnable,
            company_id: company_id
        }
        createContract(data).then(async (res) => {
            await toast({
                title: "Contrato criado com sucesso",
                status: "success",
                description: res.data.message,
                duration: 9000,
                isClosable: true,
            })
            setContractprops(data);
            setIsLoading(false);
            setContractIsCreated(true)
            navigate('/organizer')
        })
            .catch((err) => {
                setIsLoading(false);
                toast({
                    title: "Erro ao criar contrato",
                    description: err.response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            })

    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <h1>
                Dados da Contrato
            </h1>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (Number(companyFeePercent) <= 0 || Number(finalCustomerFeePercent) <= 0) {
                        toast({
                            title: "Erro ao criar contrato",
                            description: "A porcentagem da taxa da empresa e do cliente final devem ser maiores que 0",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        return;
                    }
                    handleSaveContract();
                    handleCreateContract();
                }}
            >
                <FormControl className="itemForm">
                    <FormLabel>
                        Porcentagem da taxa da empresa
                    </FormLabel>
                    <Input required value={finalCustomerFeePercent} type="number" placeholder="12" onChange={(e) => setFinalCustomerFeePercent(e.target.value)} />
                </FormControl>
                <FormControl className="itemForm">
                    <FormLabel>
                        Porcentagem da taxa do cliente final
                    </FormLabel>
                    <Input value={companyFeePercent} required type="number" placeholder="10" onChange={(e) => setCompanyFeePercent(e.target.value)} />
                </FormControl>
                {!contractIsCreated && <Button height={50} type="submit" className="itemForm" colorScheme="green">
                    {
                        isLoading ? <Spinner
                            size="xs"
                            color="white"
                        /> : "Salvar"
                    }
                </Button>}
            </form>
            <Button
                colorScheme="blue"
                height={50}
                style={{
                    width: "50%",
                    marginRight: "10px",
                    marginTop: "10px"
                }}
                onClick={() => backTab(2)}
            >
                Voltar
            </Button>
        </div >
    )
}