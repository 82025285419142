import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "@chakra-ui/react";
import { FormikValues, useFormik } from 'formik';

import validation from "./validations";

import "./style.css";
import {  listEvents } from '../../../../services/api';
import ErrorMessageForm from "../../../../components/ErrorMessageForm";
import { IEvent, IHighlightedEvent } from "../../../../interfaces/event-interface";

interface CategoryFormProps {
  onSubmit: (values: IHighlightedEvent) => void;
  onClose: () => void;
  initialValues?: FormikValues;
  categoryId?: number;
}

export default function HighlightedEventForm({
  onSubmit,
  onClose,
  initialValues,
}: CategoryFormProps) {
  const initialFormValues: FormikValues = {
    eventId: undefined,
    expiresAt: undefined,
  };
  const [events, setEvents] = useState<IEvent[]>([] as IEvent[])

  useEffect(() => {
    getEvents()
  }, [])

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit({
        eventId: Number(values.eventId),
        expiresAt: values.expiresAt,
      } as IHighlightedEvent);
      onClose();
    },
    validationSchema: validation
  })

  function getEvents() {
    listEvents().then(response => {
      setEvents(response.data.items)
    })
  }

  return (
    <>
      <Select
        className="input"
        name="eventId"
        onChange={handleChange}
        value={values.eventId}
        errorBorderColor="pink.400"
        isInvalid={!!errors.eventId && !!touched.eventId}
      >
        <option disabled value={undefined} selected>
          Selecione o evento
        </option>
        {
          events.map((event) => (
            <option key={event.id} value={event.id}>
              {event.title}
            </option>
          ))
        }
      </Select>
      <ErrorMessageForm
        errors={errors}
        touched={touched}
        name="eventId"
      />

      {/* <Input
        className="input"
        name="expiresAt"
        placeholder="Fim do destaque"
        size="md"
        type="datetime-local"
        onChange={handleChange}
        value={
          initialValues?.expiresAt
            ? new Date(values.expiresAt as Date)
              ?.toISOString()
              ?.split(".")[0]
            : values.expiresAt
        }
        errorBorderColor="pink.400"
        isInvalid={!!errors.expiresAt && !!touched.expiresAt}
      /> */}

      <ErrorMessageForm
        errors={errors}
        touched={touched}
        name="expiresAt"
      />
      <div className="action-buttons-custom">
        <Button
          variant={"outline"}
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>
        <Button
          colorScheme={"blue"}
          type="submit"
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
        >
          Salvar
        </Button>
      </div>
    </>
  );
}
