import { Button, Flex, Grid, Select, Text } from "@chakra-ui/react"
import { Header } from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { deleteUser, getUsersByFilter } from "../../services/api";
import { UserInterface } from "./interfaces/user.interface";
import Datatable from "../../components/Datatable/datatable";
import { Pagination } from "../../components/Pagination/pagination";
import { SearchInput } from "../../components/SearchInput/search-input";
import { EditUserModal } from "./edit-user-modal";
import Alert from "../../components/Alert";
import moment from "moment";

enum UserTypeEnum {
    CUSTOMER = 'CLIENTE',
    ADMIN = 'ADMINISTRADOR',
    ORGANIZER = 'ORGANIZADOR',
}

export const Users = () => {
    const [users, setUsers] = useState<UserInterface[]>([])
    const [lastPage, setLastPage] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(50)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [searchInput, setSearchInput] = useState<string>('')
    const [userType, setUserType] = useState<string>('')
    const [userSelected, setUserSelected] = useState<UserInterface | null>(null)
    const [isOpenModalEditUser, setIsOpenModalEditUser] = useState<boolean>(false)
    const [isOpenModalDeleteUser, setIsOpenModalDeleteUser] = useState<boolean>(false)

    const userTypesArray = [
        {
            id: 1,
            label: 'TODOS',
            value: '',
        },{
            id: 1,
            label: UserTypeEnum.CUSTOMER,
            value: 'CUSTOMER',
        },
        {
            id: 2,
            label: UserTypeEnum.ORGANIZER,
            value: 'ORGANIZER',
        },
        {
            id: 3,
            label: UserTypeEnum.ADMIN,
            value: 'ADMIN',
        }
    ]

    const columns = [
        { label: 'ID', value: 'id'},
        { label: 'NOME', value: 'name'},
        { label: 'EMAIL', value: 'email'},
        { label: 'TELEFONE', value: 'phone'},
        { label: 'TIPO DE USUÁRIO', value: 'user_type'},
        { label: 'DATA CADASTRO', value: 'created_at'},
        { label: 'AÇÕES', value: 'actions'},
    ]

    const rows = users.map(user => {
        return {
            id: user.id,
            name: user.name,
            email: user.email,
            phone: user.phone,
            user_type: UserTypeEnum[user.user_type],
            created_at: moment(user.created_at).format("DD/MM/YYYY HH:mm:ss"),
            actions: [

                <Button
                    marginRight={5}
                    onClick={() => {
                        setUserSelected(user)
                        setIsOpenModalEditUser(true)
                    }}
                >
                    EDITAR
                </Button>,

                <Button
                    color='white'
                    backgroundColor='red'
                    onClick={() => {
                        setUserSelected(user)
                        setIsOpenModalDeleteUser(true)
                    }}
                >
                    DELETAR
                </Button>,
            ]
        }
    })

    const handleSearch = async () => {

        await getUsers()
    }

    const handleDeleteUser = async () => {
        try {
            await deleteUser(userSelected!.id)
            toast.success('Usuário deletado com sucesso')
            setIsOpenModalDeleteUser(false)
            reload()
            
        } catch (error) {
            toast.error('Erro ao deletar usuário!')
        }
    }

    function reload(){
        handleSearch()
    }

    const getUsers = async () => {
        setIsLoading(true)
        try {
            const {data} = await getUsersByFilter(currentPage, perPage, searchInput, userType)
            const {items, meta} = data
            setUsers(items)

            setLastPage(meta.totalPages)
            setCurrentPage(meta.currentPage)
            setPerPage(meta.itemsPerPage)
            
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar os usuários!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        handleSearch()
    }, [currentPage, perPage])

    useEffect(() => {
        if (searchInput.length === 0 && !isLoading) {
            handleSearch();
        }
    }, [searchInput]);
    
    return(
        <>
            <Header />
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div className="table-header">
                            <Text fontSize={"4xl"}>Usuários</Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                            }}  
                        >

                            <div>
                                <Select
                                    style={{
                                        width:'12rem'
                                    }}
                                    className="input"
                                    name="user_type"
                                    placeholder="Selecione o tipo de usuário"
                                    onChange={(e) => setUserType(e.target.value)}
                                    value={userType}
                                    errorBorderColor="pink.400"
                                >
                                    {userTypesArray.map((userType) => (
                                    <option key={userType.id} value={userType.value}>
                                        {userType.label}
                                    </option>
                                    ))}
                                </Select>
                            </div>

                            <SearchInput
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                handleSearch={handleSearch}
                                placeholder="Busque pelo nome do usuário ou email"
                            />
                        </div>

                        <Datatable
                            rows={rows}
                            columns={columns}
                            isLoading={isLoading}
                        />

                        <Pagination
                            lastPage={lastPage}
                            currentPage={currentPage}
                            setLastPage={setLastPage}
                            setCurrentPage={setCurrentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />

                    </div>
                </Flex>
            </Grid>

            <EditUserModal
                isOpen={isOpenModalEditUser}
                setIsOpen={setIsOpenModalEditUser}
                user={userSelected}
                reload={reload}
            />

            <Alert
                title={"DELETAR USUÁRIO?"}
                confirmActionMessage={"Tem certeza que deseja deletar este usuário?"}
                confirmActionButtonText={"Deletar"}
                cancelActionButtonText={"Cancelar"}
                isOpen={isOpenModalDeleteUser}
                onClose={() => setIsOpenModalDeleteUser(false)}
                cancelRef={() => {}}
                onConfirm={handleDeleteUser}
                confirmButtonText={""}
            />

            <ToastContainer />

        </>
    )
}