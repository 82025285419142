import { Box, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCompany, getUser } from "../../services/auth";
import { ICompanyByUser } from "../../interfaces/company-interface";

export const Header = () => {
    const [company, setCompany] = useState<ICompanyByUser>({} as ICompanyByUser)
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const user = getUser()
    const role = user.user_type;

    useEffect(() => {
        setIsLoading(true)

        const res = getCompany();
        setCompany(res)
        setIsLoading(false)
    }, []);

    return (
        <></>
        // <Box
        //     as="header"
        //     w="100%"
        //     h="50"
        //     bg="gray.800"
        //     color="white"
        //     backgroundColor={"#1D1D1D"}
        //     display="flex"
        //     alignItems="center"
        //     justifyContent="center"
        // >
        //     <span
        //         style={{
        //             fontStyle: "italic",
        //         }}
        //     >
        //         Onde é Hoje?
        //     </span> <span
        //         style={{
        //             marginLeft: 8,
        //             fontWeight: 'bolder'
        //         }}
        //     >
        //         {
        //             role === 'ADMIN' ? null : isLoading ? <Spinner /> : `${company.name}`
        //         }

        //     </span>
        // </Box>
    );
}