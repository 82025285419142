import { useRef } from "react";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { Formik } from "formik";
import validation from "./validation";
import ErrorMessageForm from "../../../../components/ErrorMessageForm";
import { ITicket } from "../../../../interfaces/ticket-interface";

interface FormValues {
  title?: string;
  description?: string;
  price?: number;
  quantity?: number;
  initialDate?: Date;
  finalDate?: Date;
}

interface TicketFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: FormValues;
  onSubmit: (values: ITicket) => void;
}

export default function TicketForm({
  isOpen,
  onClose,
  initialValues,
  onSubmit,
}: TicketFormProps) {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const initialFormValues: Partial<ITicket> = {
    title: initialValues?.title,
    description: initialValues?.description,
    price: initialValues?.price,
    quantity: initialValues?.quantity,
    initialDate: initialValues?.initialDate,
    finalDate: initialValues?.finalDate,
  };

  const toLocalISOString = (date: Date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, -1);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values) => {
          onSubmit(values as ITicket);
        }}
        validationSchema={validation}
      >
        {({ values, handleChange, handleSubmit, touched, errors }) => (
          <ModalContent>
            <ModalHeader>
              {initialValues?.title ? "Editar Ingresso" : "Novo Ingresso"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Título do ingresso</FormLabel>
                <Input
                  ref={initialRef}
                  name="title"
                  placeholder="Ex. VIP"
                  onChange={handleChange}
                  value={values.title}
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.title && !!touched.title}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="title"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Quantidade</FormLabel>
                <Input
                  name="quantity"
                  type={"number"}
                  placeholder="Ex. 5"
                  onChange={handleChange}
                  value={values.quantity}
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.quantity && !!touched.quantity}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="quantity"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Preço</FormLabel>
                <Input
                  name="price"
                  type={"number"}
                  placeholder="Ex. R$ 50,00"
                  onChange={handleChange}
                  value={values.price}
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.price && !!touched.price}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="price"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Data de início</FormLabel>
                <Input
                  name="initialDate"
                  type={"datetime-local"}
                  onChange={handleChange}
                  // value={
                  //   values.initialDate &&
                  //   new Date(values.initialDate as Date)
                  //     ?.toISOString()
                  //     ?.split(".")[0]
                  // }
                  value={
                    values.initialDate
                      ? toLocalISOString(new Date(values.initialDate))
                      : ''
                  }
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.initialDate && !!touched.initialDate}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="initialDate"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Data de fim</FormLabel>
                <Input
                  name="finalDate"
                  type={"datetime-local"}
                  onChange={handleChange}
                  // value={
                  //   values.finalDate &&
                  //   new Date(values.finalDate as Date)
                  //     ?.toISOString()
                  //     ?.split(".")[0]
                  // }
                  value={
                    values.finalDate
                      ? toLocalISOString(new Date(values.finalDate))
                      : ''
                  }
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.finalDate && !!touched.finalDate}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="finalDate"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Descrição</FormLabel>
                <Textarea
                  name="description"
                  placeholder="Esse ingresso é open bar 🍺😋"
                  onChange={handleChange}
                  value={values.description}
                  errorBorderColor="pink.400"
                  isInvalid={!!errors.description && !!touched.description}
                />
                <ErrorMessageForm
                  errors={errors}
                  touched={touched}
                  name="description"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={() => handleSubmit()}>
                Salvar
              </Button>
              <Button onClick={onClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  );
}
