import { HamburgerIcon, AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FaPencilAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
interface HeaderCompanyProps {
    companyName: string;
    companyId: number;
    onOpenModalSelect: () => void;
}
export const HeaderCompany = ({ companyName, companyId, onOpenModalSelect }: HeaderCompanyProps) => {
    ///   franchise/select/edit/:id

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    return (
        <Box
            as="header"
            w="100%"
            h="50"
            bg="gray.800"
            color="white"
            backgroundColor={"#1D1D1D"}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    paddingLeft: '32px'
                }}
            >
                <span
                    style={{
                        fontStyle: "italic",
                    }}
                >
                    Onde é Hoje?
                </span>
            </div>

            <div
                style={{
                    width: '100%',
                }}
            >
                <span
                    style={{
                        marginLeft: 8,
                        fontWeight: 'bolder',
                        fontSize: 20,
                    }}
                >
                    {companyName}
                </span>
            </div>

            <div
                style={{
                    width: '30%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    paddingRight: '32px'
                }}
            >
                <Menu
                >
                    <MenuButton
                        colorScheme='white'
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                    />
                    <MenuList
                        color='black'
                    >
                        <MenuItem
                            icon={<FaPencilAlt />}
                            onClick={onOpenModalSelect}
                        >
                            Editar dados empresa
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </Box >
    );
}