import { useEffect, useState } from "react";
import { verifyStatusFranchise } from "../../../../services/api"
import { Badge, CircularProgress, Tooltip } from "@chakra-ui/react";
import { IResponseVerifyFranchise } from "../../../../interfaces/franchise-interface";
import { RiAlertFill } from "react-icons/ri";

interface IPropsCompanyPending {
    franchiseId: number
}

export const ComponentStatusFranchise = ({ franchiseId }: IPropsCompanyPending) => {
    const [franchisePending, setFranchisePending] = useState<IResponseVerifyFranchise>({} as IResponseVerifyFranchise);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasPendecies, setHasPendecies] = useState<boolean>(false);

    const handleVerifyFranchise = (franchiseId: number) => {
        setIsLoading(true);
        verifyStatusFranchise(franchiseId).then((response) => {
            const { statusCode, data } = response.data;
            const isHasPendenci = handleVerifyHasPendecies(response.data);
            if (isHasPendenci) {
                setHasPendecies(true);
            }
            setFranchisePending(response.data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    const handleVerifyHasPendecies = (data: IResponseVerifyFranchise) => {
        const adminFranchise = data.data.admins;
        if (adminFranchise.length <= 0) {
            return true;
        }
        const banckAccountFranchise = data.data.bankAccounts;
        if (banckAccountFranchise.length <= 0) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        handleVerifyFranchise(franchiseId);
    }, [])

    return (
        <>
            {
                isLoading ? <CircularProgress isIndeterminate color="green.300" /> :
                    hasPendecies &&
                    <RiAlertFill
                        color="orange"
                        size={20}
                        style={{ marginRight: 5 }}
                    />
            }
        </>

    )
}