import * as Yup from 'yup';
const validation = Yup.object().shape({
    title: Yup.string().required('Título do evento é obrigatório'),
    description: Yup.string().required('Descrição do evento é obrigatório'),
    number: Yup.string().required('Número é obrigatório'),
    initialDate: Yup.date().required('Data inicial do evento é obrigatório'),
    finalDate: Yup.date().required('Data final do evento é obrigatório'),
    zip: Yup.string().required('CEP é obrigatório').test('isAllowed', 'Cidade não permitida', (value) => {
        return ['65800000'].includes(String(value)?.replace(/[^0-9]/g, ''));
    }),
    street: Yup.string().required('Rua é obrigatório'),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    categoryId: Yup.string().required('Categoria é obrigatório'),
    company_id: Yup.string()
});

export default validation;