import { useEffect, useState } from "react"
import { createBankAccountFranchise, getBackAccountById, listBanks } from "../../../../../services/api"
import { Button, CircularProgress, Flex, FormControl, FormLabel, Grid, Input, Select, Switch, useToast } from "@chakra-ui/react"
import { IBank } from "../../../../../interfaces/banckaccount-interface";
import { ICreateBankAccountFranchise } from "../../../../../interfaces/bank-account";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../../components/Sidebar";

export const ContaBancariaFranquiaFormEdit = () => {
    const [holderName, setHolderName] = useState<string>('')
    const [bankCode, setBankCode] = useState<string>('')
    const [branchNumber, setBranchNumber] = useState<string>('')
    const [branchCheckDigit, setBranchCheckDigit] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountCheckDigit, setAccountCheckDigit] = useState<string>('')
    const [accountType, setAccountType] = useState<string>('checking')
    const [transferEnabled, setTransferEnabled] = useState<boolean>(true)
    const [transferInterval, setTransferInterval] = useState<string>('daily')
    const [transferDay, setTransferDay] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banks, setBanks] = useState<IBank[]>([])
    const [franchiseId, setFranchiseId] = useState<string>('')
    const [franchiseType, setFranchiseType] = useState<string>('')
    const [document_number, setDocumentNumber] = useState<string>('')
    const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false)

    let { id } = useParams();

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const response = await getBackAccountById({ id: Number(id) });
        const { data } = response.data;
        setFranchiseId(data.franchise_id ? data.franchise_id.toString() : '');
        setFranchiseType(data.holder_type);
        setDocumentNumber(data.holder_document);
    }

    const handleGetBanks = () => {
        setIsLoadingBanks(true)
        listBanks().then((response) => {
            if (response.data.data) {
                setBanks(response.data.data);
            }
            setIsLoadingBanks(false)
        }).catch((error) => {
            console.log(error)
            setIsLoadingBanks(false)
        }).finally(() => {
            setIsLoadingBanks(false)
        })
    }
    const toast = useToast();

    useEffect(() => {
        handleGetBanks()
    }, []);

    const handleSetBank = (value: string) => {
        const bank = banks.find((bank) => bank.long_name === value)
        setBankName(value)
        if (bank) {
            if (bank?.code) {
                setBankCode(bank.code.toString())
            } else {
                toast({
                    title: "Código do banco não encontrado",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })

            }
        }
    }

    const handleCreateBankAccount = async () => {
        setIsLoading(true)
        const data = {
            account_check_digit: accountCheckDigit,
            account_number: accountNumber,
            account_type: accountType,
            transfer_interval: transferInterval,
            bank_code: bankCode,
            branch_check_digit: branchCheckDigit,
            branch_number: branchNumber,
            holder_document: document_number,
            franchise_id: Number(franchiseId),
            holder_name: holderName,
            holder_type: franchiseType,
            transfer_enabled: transferEnabled,
            transfer_day: Number(transferDay),
        } as ICreateBankAccountFranchise

        createBankAccountFranchise(data).then((response) => {
            if (response.status === 201) {
                toast({
                    title: "Conta bancária atualizada com sucesso",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            }
            setIsLoading(false)
            navigate('/franchise/' + franchiseId)
        })
            .catch((error) => {
                setIsLoading(false)
                toast({
                    title: "Erro ao atualizar conta bancária",
                    status: "error",
                    description: error.response.data.message,
                    duration: 9000,
                    isClosable: true,
                })
            });
    }

    return (
        <>
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <h1>
                                Conta Bancaria do Recebedor
                            </h1>
                            <form
                                style={{
                                    borderTop: "1px solid #ccc",
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleCreateBankAccount()
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            padding: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <FormControl>
                                                <FormLabel>Nome do titular</FormLabel>
                                                <Input value={holderName} onChange={(e) => setHolderName(e.target.value)} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Nome do banco</FormLabel>
                                                <Input
                                                    value={bankName}
                                                    onChange={(e) => handleSetBank(e.target.value)}
                                                    list="banks"
                                                />
                                                {
                                                    isLoadingBanks && <Flex>
                                                        <CircularProgress
                                                            isIndeterminate
                                                            color="green"
                                                            size="24px"
                                                        />
                                                    </Flex>
                                                }
                                                <datalist id="banks">
                                                    {banks.map((bank) => (
                                                        <option key={bank.code} value={bank.long_name} />
                                                    ))}
                                                </datalist>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Número da agência</FormLabel>
                                                <Input value={branchNumber} type="number" onChange={(e) => setBranchNumber(e.target.value)} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Dígito da agência</FormLabel>
                                                <Input value={branchCheckDigit} type="number" onChange={(e) => setBranchCheckDigit(e.target.value)} />
                                            </FormControl>
                                        </div>

                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <FormControl>
                                                <FormLabel>Número da conta</FormLabel>
                                                <Input value={accountNumber} type="number" onChange={(e) => setAccountNumber(e.target.value)} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Dígito da conta</FormLabel>
                                                <Input value={accountCheckDigit} type="number" onChange={(e) => setAccountCheckDigit(e.target.value)} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Tipo de conta</FormLabel>
                                                <Select value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                                                    <option value="checking">Corrente</option>
                                                    <option value="savings">Poupança</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Transferência habilitada</FormLabel>
                                                <Switch
                                                    isChecked={transferEnabled}
                                                    onChange={(e) => setTransferEnabled(e.target.checked)}
                                                    colorScheme="green"
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Intervalo de transferência</FormLabel>
                                                <Select value={transferInterval} onChange={(e) => setTransferInterval(e.target.value)}>
                                                    <option value="daily">Diário</option>
                                                    <option value="weekly">Semanal</option>
                                                    <option value="monthly">Mensal</option>
                                                </Select>
                                            </FormControl>
                                            {
                                                transferInterval === "monthly" && <FormControl>
                                                    <FormLabel>Dia de transferência</FormLabel>
                                                    <Input value={transferDay} type="number" maxLength={2} minLength={1} onChange={(e) => setTransferDay(e.target.value)} />
                                                </FormControl>
                                            }

                                            {
                                                transferInterval === "weekly" && <FormControl>	<FormLabel>Dia de transferência</FormLabel>
                                                    <Select value={transferDay} onChange={(e) => setTransferDay(e.target.value)}>
                                                        <option value="1">Segunda-feira</option>
                                                        <option value="2">Terça-feira</option>
                                                        <option value="3">Quarta-feira</option>
                                                        <option value="4">Quinta-feira</option>
                                                        <option value="5">Sexta-feira</option>
                                                        <option value="6">Sábado</option>
                                                        <option value="7">Domingo</option>
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            borderTop: "1px solid #ccc",
                                            padding: "10px",
                                            marginTop: "20px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            colorScheme="red"
                                            style={{
                                                width: "100%",
                                                marginRight: "10px"
                                            }}
                                            onClick={() => {
                                                if (!isLoading) {
                                                    handleNavigate('/franchise/' + franchiseId);
                                                }
                                            }}
                                            height={50}
                                        >
                                            {isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Cancelar'}
                                        </Button>
                                        <Button
                                            colorScheme="green"
                                            width={"100%"}
                                            height={"50px"}
                                            type="submit"
                                            isDisabled={isLoading}
                                        >
                                            {isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Salvar'}
                                        </Button>
                                    </div>
                                </div>
                            </form>

                        </div >
                    </div>
                </Flex>
            </Grid>
        </>
    )
}