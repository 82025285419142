import { Button, Flex, FormControl, FormLabel, Grid, Input, theme, useToast, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signup } from '../../services/api'
import Logo from '../../assets/logo.png';
import './styles.css';
export default function Signup() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [cpf, setCpf] = useState('')
    const [birthDate, setBirthDate] = useState('')

    const toast = useToast()
    const navigate = useNavigate()

    function handleNavigate() {
        navigate('/signin')
    }

    async function handleClick() {

        if (password !== passwordConfirmation) {
            toast({
                title: "As senhas não conferem.",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
            return
        }

        try {
            await signup(email, password, name, phone, cpf, birthDate)

            navigate('/signin')

            toast({
                title: "Cadastro efetuado com sucesso.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Erro ao cadastrar usuário.",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return (
        <Grid minH="100vh" minW="100vw" p={3} padding={0}>
            <Flex justifyContent={'center'} alignItems={'center'} minH='100vh' width='100vw' backgroundColor={theme.colors.green}>
                <FormControl minH='50vh' width={'25vw'} backgroundColor={theme.colors.green}>
                    <div className='img'>
                        <img src={Logo} alt="Logo" width="200" height="200" className='imagestyle' />
                    </div>
                    <FormControl>
                        <FormLabel>Nome</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='Nome' type={'text'} value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='Email' type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Telefone</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='Telefone' type={'tel'} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>CPF</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='CPF' type={'number'} value={cpf} onChange={(e) => setCpf(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Data De Nascimento</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='00/00/0000' type={'date'} value={birthDate} onChange={(e) => setBirthDate(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Senha</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='****' type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Confirmar Senha</FormLabel>
                        <Input focusBorderColor='pink.700' placeholder='****' type={'password'} value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    </FormControl>

                    <Text mt={5} color='GrayText' cursor={'pointer'} onClick={handleNavigate} _hover={

                        { color: 'purple', textDecorationColor: 'purple', fontWeight: 'bold', fontSize: '1.1rem', }
                    } >Já tenho uma conta</Text>

                    <Button variant={'outline'} marginTop={8} width={'25vw'} onClick={handleClick} style={{
                        backgroundColor: 'purple', color: 'white'
                    }} _hover={{ transform: "scale(1.2)" }}>Criar</Button>
                    <Text color='gray.600' style={{ textAlign: 'center' }}>Ao se cadastrar automaticamente você concorda com os <Text as='ins' style={{ cursor: 'pointer' }}>termos de uso e política de privacidade.</Text>
                    </Text>
                </FormControl>
            </Flex>
        </Grid>
    )
}
