// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.tr :hover{
    cursor: pointer;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100px;
}

.table-container {
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/screens/HighlightedEvents/components/HighlightedEventsTable/style.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;IAEnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n\n.tr :hover{\n    cursor: pointer;\n}\n\n.action-buttons {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    width: 100px;\n}\n\n.table-container {\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
